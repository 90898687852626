import React from "react";

import "../../stylesheets/components/pagination.css";

const Pagination = ({ resultsPerPage, totalResults, paginate, currPage }) => {

    const pageNumbers = [];

    for (let i=1; i <= Math.ceil(totalResults / resultsPerPage); i++){
        pageNumbers.push(i)
    }

    return (

        <nav>

            <ul
                className="pagination">

                { pageNumbers.map(num => (

                    <li
                        key={ num }
                        className="page-num">

                        <button
                            onClick={ () => paginate(num) }
                            className={ "page-link" + (num === currPage ? " sel-page" : "") }>
                            { num }
                        </button>

                    </li>

                ))}

            </ul>

        </nav>
    )
}

export default Pagination;