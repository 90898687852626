import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip        from "react-bootstrap/Tooltip";

const OverlayTriggerIcon = ({ placement, overlay_text, action, css_class, icon }) => {
    return (
        <OverlayTrigger
            placement={ placement }
            overlay={
                <Tooltip>
                    { overlay_text }
                </Tooltip>
            }>
            <FontAwesomeIcon
                icon={ icon }
                className={ css_class }
                onClick= { () => action() }/>
        </OverlayTrigger>
    )
}

export default OverlayTriggerIcon;