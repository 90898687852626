import React from "react";

import SearchResultSkeleton from "./SearchResultSkeleton";

import "../../stylesheets/components/skeleton-search-results.css";

const SearchResultsSkeleton = ({ nResults }) => {

    return (

        <div
            className="search-results skeleton">

            { [...Array(nResults).keys()].map(i => (
               <SearchResultSkeleton
                    key={ i }/>
            ))}

        </div>

    )
}

export default SearchResultsSkeleton;
