import React, { useState } from "react";
import { connect }         from "react-redux";

import Modal       from "react-bootstrap/Modal";
import CSVReader   from "react-csv-reader";
import { CSVLink } from "react-csv";

import {
        updateCollection,
        updateSearchResults } from "../../redux/actions/userActions";

import "../../stylesheets/components/selection-manager.css";

const SEARCH_RESULTS_INDEX = -1;

const SelectionManager = ({ totalNumArticles, selectedCollectionIndex,
                            selectedArticles, selectedCordUIDs, collections,
                            addArticlesToCollection, addArticlesToSearchResults,
                            deleteArticlesFromCollection,
                            deleteArticlesFromSearchResults,
                            onDeleteComplete }) => {


    const [showMoveModal, setShowMoveModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showImportModal, setShowImportModal] = useState(false);

    const [target, setTarget] = useState(0);
    const [delFromSource, setDelFromSource] = useState(false);
    const [CORDUIDsToAdd, setCORDUIDsToAdd] = useState([]);
    const [showImportError, setShowImportError] = useState(false);

    const numSelectedArticles = selectedArticles.size;

    const move = (deleteFromSource) => {

        if (numSelectedArticles === 0 || target === selectedCollectionIndex ||
            target < SEARCH_RESULTS_INDEX || target >= collections.length) return;

        if (target === SEARCH_RESULTS_INDEX){
            addArticlesToSearchResults(selectedCordUIDs);
        } else{
            addArticlesToCollection(collections[target].collectionID, selectedCordUIDs);
        }

        if (deleteFromSource){
            _delete();
        }

        setShowMoveModal(false);
        setTarget(0);
        setDelFromSource(false);
    }

    const _delete = () => {

        if (selectedArticles.size === 0) return;

        if (selectedCollectionIndex === SEARCH_RESULTS_INDEX){
            deleteArticlesFromSearchResults(selectedCordUIDs)
        } else {
            deleteArticlesFromCollection(collections[selectedCollectionIndex].collectionID, selectedCordUIDs);
        }

        onDeleteComplete();
        setShowDeleteModal(false);
        setTarget(0);
    }

    const onImport = () => {
        if (CORDUIDsToAdd.length === 0){
            setShowImportError(true);
            return;
        }

        addArticlesToCollection(collections[target].collectionID, CORDUIDsToAdd);

        setShowImportError(false);
        setCORDUIDsToAdd([]);
        setShowImportModal(false);
    }

    const parseFileData = (data) => {

        if (data.length < 2){
            setShowImportError(true)
            return;
        }

        let index = data[0].indexOf("cord_uid");

        if (index === -1){
            setShowImportError(true)
            return;
        }

        let cord_uids = [];

        for (let i=1; i<data.length; i++){
            let val = data[i][index];
            if (val === undefined || !(typeof val === "string")) continue;
            cord_uids.push(val);
        }

        setCORDUIDsToAdd(cord_uids);
    }

    let selectedCollectionName = selectedCollectionIndex === SEARCH_RESULTS_INDEX
                                ? "Active Collection"
                                : collections[selectedCollectionIndex].alias

    return (

        <div
            className="collection-manager">

            <p>
                <b>Selected Collection:</b>
                &nbsp;
                { selectedCollectionName }
            </p>

            <p>
                <b>No. of Articles(Total):</b>
                &nbsp;
                { totalNumArticles }
            </p>

            <p>
                <b>No. of Articles(Selected):</b>
                &nbsp;
                { numSelectedArticles }
            </p>

            <div
                className="actions">

                <button
                    className="move"
                    disabled={ numSelectedArticles === 0 || collections.length === 0 }
                    onClick={ () => setShowMoveModal(true) }>
                    Move
                </button>

                <Modal
                    show={ showMoveModal }
                    onHide={() => { setShowMoveModal(false); setTarget(null) } }
                    centered
                    dialogClassName="modal-90w"
                    aria-labelledby="move-modal-title"
                    className="move-modal">

                    <Modal.Header
                        closeButton>

                        <Modal.Title
                            id="move-modal-title">
                            Move Selected Articles
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        <p>
                            <b>Source:</b> { selectedCollectionName }
                        </p>

                        <label
                            htmlFor="targetSelector">
                            <b>Target:</b>
                        </label>

                        <select
                            id="targetSelector"
                            onChange={ (e) => setTarget(parseInt(e.target.value)) }>

                            <option
                                value={ SEARCH_RESULTS_INDEX }
                                disabled={ SEARCH_RESULTS_INDEX === selectedCollectionIndex }>
                                Search Results
                            </option>

                            { collections.map((briefcase, index) =>
                            <option
                                key={ briefcase.collectionID }
                                value={ index }
                                disabled={ index === selectedCollectionIndex }>
                                { briefcase.alias }
                            </option>
                            )}

                        </select>

                        <br/>

                        <label
                            htmlFor="delFromSourceInput">
                            <b>Delete from Source?</b>
                        </label>

                        <input
                            id="delFromSourceInput"
                            type="checkbox"
                            onChange={ () => setDelFromSource(!delFromSource)}/>

                        <button
                            className="move"
                            onClick={ () => move(delFromSource)}>
                            Move
                        </button>

                    </Modal.Body>
                </Modal>

                <button
                    className="delete"
                    onClick={ () => setShowDeleteModal(true) }
                    disabled={ numSelectedArticles === 0 }>
                    Delete
                </button>

                <Modal
                    show={ showDeleteModal }
                    onHide={() => { setShowDeleteModal(false); setTarget(null) } }
                    centered
                    dialogClassName="modal-90w"
                    aria-labelledby="delete-modal-title"
                    className="delete-modal">

                    <Modal.Header closeButton>

                        <Modal.Title
                            id="delete-modal-title">
                            Delete Selected Articles
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        <p>
                            Are you sure?
                        </p>

                        <button
                            className="delete"
                            onClick={ () => _delete()}>
                            Confirm
                        </button>

                    </Modal.Body>

                </Modal>

                <div
                    className="load-btns">

                    <button
                        className="export"
                        onClick={ () => setShowImportModal(true) }
                        disabled={ selectedCollectionIndex === SEARCH_RESULTS_INDEX }>
                        Import
                    </button>

                    <Modal
                        show={ showImportModal }
                        onHide={() => { setShowImportModal(false); setShowImportError(false); setCORDUIDsToAdd([]) } }
                        centered
                        dialogClassName="modal-90w"
                        aria-labelledby="import-modal-title"
                        className="delete-modal import-modal">

                        <Modal.Header closeButton>

                            <Modal.Title
                                id="import-modal-title">
                                Import Articles
                            </Modal.Title>

                        </Modal.Header>

                        <Modal.Body>

                            <p
                                className="import-info">
                                Please upload a <b>.csv</b> file that contains the CORD UIDs (Under the column name <span>cord_uid</span>) of the articles that you would like to add to this collection.
                            </p>

                            <CSVReader
                                onFileLoaded={(data, fileInfo) => parseFileData(data)}
                                onError={ () => setShowImportError(true) }/>

                            { showImportError &&
                            <span
                                className="import-error-msg">
                                Sorry, something went wrong.<br/>Please check your import file before trying again.
                            </span>}

                            <button
                                className="btn-blue"
                                onClick={ () => onImport()}>
                                Import
                            </button>

                        </Modal.Body>

                    </Modal>

                    <button
                        className="export"
                        disabled={ numSelectedArticles === 0 }>

                        <CSVLink
                            data={ Array.from(selectedArticles) }
                            filename={ "export-" + new Date().toISOString() + ".csv" }
                            onClick={() => selectedArticles.size > 0 }>
                            Export
                        </CSVLink>

                    </button>
                </div>

            </div>

        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        addArticlesToCollection: (collectionID, cord_uids) => dispatch(updateCollection(true, collectionID, cord_uids)),
        deleteArticlesFromCollection: (collectionID, cord_uids) => dispatch(updateCollection(false, collectionID, cord_uids)),
        addArticlesToSearchResults: (cord_uids) => dispatch(updateSearchResults(true, cord_uids)),
        deleteArticlesFromSearchResults: (cord_uids) => dispatch(updateSearchResults(false, cord_uids))
    }
};

const mapStateToProps = (state) => ({
    collections: state.collections
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectionManager);
