import React, { Component } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect }              from "react-router-dom";
import axios                from "axios";
import jwtDecode            from 'jwt-decode';

import { connect } from "react-redux";

import { loadReCaptcha } from "react-recaptcha-v3";

import { fetchUserData, setAuthenticated, setUnAuthenticated } from "./redux/actions/userActions";

import structured_search from "./pages/structured_search";
import verification      from "./pages/verification";
import collections       from "./pages/collections";
import visualiser        from "./pages/visualiser";
import reset_pw          from "./pages/reset_pw";
import search            from "./pages/search";
import about             from "./pages/about";
import error             from "./pages/404";

import Navbar from "./components/Utilities/Navbar";
import Footer from "./components/Utilities/Footer";

import "./App.css";

// Production API URL
axios.defaults.baseURL = 'https://covid-see2.com:443/api';

// Development API URL
// axios.defaults.baseURL = 'http://127.0.0.1:5000/api';

class App extends Component {

	componentDidMount(){

		loadReCaptcha("6Lcj7sAZAAAAABt11lfhgT_o1SFH6Z9Nn4JNSLBz");

		// extract the auth token from the browser
		const token = localStorage.covidSeeAuthToken;
		if (token) {

			const decodedToken = jwtDecode(token);

			// if the token has expired, redirect the user to the login page
			if (decodedToken.exp * 1000 < Date.now()) {
				localStorage.removeItem("covidSeeAuthToken");
				this.props.setUnAuthenticated();
				delete axios.defaults.headers.common['Authorization'];
			}
			// else, update the global auth info
			else {
				axios.defaults.headers.common['Authorization'] = `Bearer ${ token }`;
				this.props.setAuthenticated(decodedToken.name);
			}
		}

		this.props.fetchUserData();
	}

	render(){
		return (
			<Router>

				<Navbar
					location={ this.props }/>

				<Switch>

					{/* landing page */}
					<Route
						exact
						path="/">
						<Redirect to="/search" />
					</Route>

					{/* landing page */}
					<Route
						exact
						path="/about"
						component={ about }/>

					{/* verification page */}
					<Route
						exact
						path="/verify"
						component={ verification }/>

					{/* pw reset page */}
					<Route
						exact
						path="/reset_pw"
						component={ reset_pw }/>

					{/* IR Search page */}
					<Route
						exact
						path="/search"
						component={ search }/>

					{/* Structured Search page */}
					<Route
						exact
						path="/structured-search"
						component={ structured_search }/>

					{/* visualiser page */}
					<Route
						exact
						path="/visualiser"
						component={ visualiser }/>

					{/* collection management page */}
					<Route
						exact
						path="/collections"
						component={ collections }/>

					{/* error page */}
					<Route
						component={ error }
						path="/*"/>

				</Switch>

				<Footer />

			</Router>
		)
	}
}

const mapDispatchToProps = dispatch => {
	return {
		fetchUserData: () => dispatch(fetchUserData()),
		setAuthenticated: (name) => dispatch(setAuthenticated(name)),
		setUnAuthenticated: () => dispatch(setUnAuthenticated())
	};
};

export default connect(null, mapDispatchToProps)(App);
