import React       from "react";

import Modal from "react-bootstrap/Modal";

// images
import ss1 from "../../data/ss_help_images/ss1.png";
import ss2 from "../../data/ss_help_images/ss2.png";
import ss3 from "../../data/ss_help_images/ss3.png";
import ss4 from "../../data/ss_help_images/ss4.png";
import ss5 from "../../data/ss_help_images/ss5.png";
import ss6 from "../../data/ss_help_images/ss6.png";
import ss7 from "../../data/ss_help_images/ss7.png";
import ss8 from "../../data/ss_help_images/ss8.png";

import "../../stylesheets/components/ss-help-modal.css";

const SSHelpModal = ({ show, onHide }) => {
    return (

        <Modal
			show={ show }
			onHide={ onHide }
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			scrollable
			className="ss-help-modal">

		<Modal.Header
			closeButton>

			<Modal.Title
				id="contained-modal-title-vcenter"
				className="modal-title">
				Structured Search Usage Guide
			</Modal.Title>

		</Modal.Header>

		<Modal.Body>

            <section>

                <h4
                    className="sub-title">
                    Building a Query:
                </h4>

                <p
                    className="help-text">
                    • Search for MESH Terms by using the search bar. Once you find the term that you're looking for, click on it to add it to the Query.
                </p>

                <img
                    className="help-img"
                    src={ ss1 }
                    alt="Hover over link"/>

                <p
                    className="help-text">
                    • Add logical operators to the Query by clicking on their respective buttons.
                </p>

                <img
                    className="help-img"
                    src={ ss2 }
                    alt="Hover over link"/>

                <p
                    className="help-text">
                    • Our validator will let you know whenever the Query is in an invalid format.
                </p>

                <img
                    className="help-img"
                    src={ ss3 }
                    alt="Clicking on link"/>

                <p
                    className="help-text">
                    • In the above case, adding another MESH term is one of the ways to make the Query valid once more.
                </p>

                <img
                    className="help-img"
                    src={ ss4 }
                    alt="Adding article to focus collection"/>

            </section>

            <section>

                <h4
                    className="sub-title">
                    Other features:
                </h4>

                <p
                    className="help-text">
                    • Build complex queries by making use of parentheses and more logical operators.
                </p>

                <img
                    className="help-img"
                    src={ ss5 }
                    alt="Remove article from focus collection"/>

                <p
                    className="help-text">
                    • Drag and drop Query components within the Query. Below, we swap the terms <span className="query-component term Population">Hospitalization</span> and <span className="query-component term Intervention">Mexico</span>
                </p>

                <img
                    className="help-img"
                    src={ ss7 }
                    alt="Remove article from focus collection"/>

                <img
                    className="help-img"
                    src={ ss8 }
                    alt="Remove article from focus collection"/>

                <p
                    className="help-text">
                    • Delete Query components by hovering over them and clicking on the delete icon.
                </p>

                <img
                    className="help-img"
                    src={ ss6 }
                    alt="Remove article from focus collection"/>

            </section>

		</Modal.Body>

		</Modal>
    )
}

export default SSHelpModal
