import React, { useState } from "react";

import Modal from "react-bootstrap/Modal";

import PwResetFrom from "./PwResetForm";
import LoginForm   from "./LoginForm";

const MODES = {
    LOGIN_REGISTER: 0,
    RESET_PW: 1
}

const LoginModal = ({ show, close, onLogin }) => {

    const [currMode, setCurrMode] = useState(MODES.LOGIN_REGISTER);

    const onToggleMode = () => {
        setCurrMode(currMode === MODES.RESET_PW ? MODES.LOGIN_REGISTER : MODES.RESET_PW);
    }

    return (
        <Modal
            show={ show }
            size="md"
            onHide={ () => close() }
            centered
            dialogClassName="modal-90w"
            aria-labelledby="login-modal-title"
            className="login-modal">

            <Modal.Header
                closeButton>
            </Modal.Header>

            <Modal.Body>
                { currMode === MODES.LOGIN_REGISTER
                ? <LoginForm
                    onLoginSuccess={ onLogin }
                    onToggleMode={ onToggleMode }/>
                : <PwResetFrom
                    onToggleMode={ onToggleMode }/>}
            </Modal.Body>

        </Modal>
    )
}

export default LoginModal
