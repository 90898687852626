import {
    createStore,
    applyMiddleware,
    compose
} from 'redux';
import thunk       from 'redux-thunk';
import userReducer from './reducers/userReducer';

const initialState = {
                        authenticated: false,
                        userName: null,
                        searchResults: [],
                        collections: [],
                    }
const middleware   = [thunk];

const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose;

const enhancer = composeEnhancers(applyMiddleware(...middleware));
const store = createStore(userReducer, initialState, enhancer);

export default store;