import React from "react";

import "../../stylesheets/components/query-component.css";

const QueryComponent = ({ val, classNames, onDelete }) => {
    return (
        <div
            className={ `query-component ${ classNames }` }>
            <span
                className="component-val">
                { val }
            </span>
            <button
                onClick={ () => onDelete() }>
                &times;
            </button>
        </div>
    )
}

export default QueryComponent;
