import React, { useState } from "react";
import axios               from "axios";

import Spinner from "react-bootstrap/Spinner";

import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";

import OverlayTriggerIcon from "./OverlayTriggerIcon";

import "../../stylesheets/components/pw-reset-form.css";

const EMAIL_RE = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const PwResetForm = ({ onToggleMode }) => {

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [resetEmail, setResetEmail] = useState("");
    const [resetLinkSent, setResetLinkSent] = useState(false);

    const onPasswordChangeRequest = (e) => {

        e.preventDefault();
        setResetLinkSent(false);
        setLoading(true);

        // clear current errors
        setErrors({});

        let errors_ = {};
        if (!EMAIL_RE.test(String(resetEmail).toLowerCase())) errors_["resetEmail"] = "Please enter a valid email address!";

        if (Object.keys(errors_).length > 0){
            setErrors(errors_);
            setLoading(false);
            return;
        }

        axios("/reset_pw_request", {
            method: "post",
            data: {
                email: resetEmail
            }
        })
        .then(response => {
            let res = response.data;
            if (res === ''){
                setResetLinkSent(true);
            } else {
                setErrors(res);
            }
            setLoading(false);
        })
        .catch(_ => {
            setErrors({ "server" : "Sorry, something went wrong."});
            setLoading(false);
        });
    }

    return (

        <div
            className="pw-reset-form">

            <div
                className="header">

                <OverlayTriggerIcon
                    placement="bottom"
                    overlay_text="back"
                    action={ onToggleMode }
                    css_class="back-btn-icon"
                    icon={ faArrowAltCircleLeft }/>

                <h2>
                    Reset Password
                </h2>

            </div>

            <form
                onSubmit={ e => onPasswordChangeRequest(e) }>

                <p>
                    Please enter the email address that you registered with:
                </p>

                <input
                    id="resetEmail"
                    className={ errors.hasOwnProperty("email") ? "error" : "" }
                    type="email"
                    required
                    onChange={ e => setResetEmail(e.target.value) }
                    value={ resetEmail }/>
                    { errors.hasOwnProperty("email") &&
                    <p
                        className="error">
                        { errors.email }
                    </p>}

                { errors.hasOwnProperty("server") &&
                <p
                    className="error">
                    { errors.server }
                </p>}

                <button
                    className="btn-blue btn-sign-in"
                    disabled={ loading }>
                    { loading
                    ? <Spinner
                        className="loading-spinner"
                        animation="border"
                        variant="light" />
                    : "Submit" }
                </button>

                { resetLinkSent && Object.keys(errors).length === 0 &&
                    <p
                        className="success">
                        We've sent a reset link to { resetEmail },<br/> provided that it's a registered email address.
                    </p>}
            </form>

        </div>
    )
}

export default PwResetForm;
