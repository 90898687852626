import React from "react";

import "../../stylesheets/components/skeleton-search-result.css";

const SearchResultSkeleton = () => {

    return (

        <div
            className="search-result skeleton">

            <div
                className="result-title skeleton">
            </div>

            <div
                className="meta-field skeleton authors">
            </div>

            <div
                className="meta-field skeleton journal">
            </div>

            {/* <div
                className="meta-field skeleton date">
            </div> */}

            <div
                className="result-abstract skeleton">
            </div>

            <div
                className="result-abstract skeleton">
            </div>

            <div
                className="result-abstract skeleton">
            </div>
        </div>

    )
}

export default SearchResultSkeleton;
