import React, { Component } from 'react';
import { connect }          from "react-redux";

import SelectionManager  from "../components/Utilities/SelectionManager";
import SelectedArticle   from "../components/Search/SelectedArticle";
import SearchResult      from "../components/Search/SearchResult";
import Collection        from "../components/Utilities/Collection";

import {
        createNewCollection,
        deleteCollection,
        changeCollectionName } from "../redux/actions/userActions";

import "../stylesheets/pages/collections.css";

const SEARCH_RESULTS_INDEX = -1;

class CollectionsPage extends Component{

    constructor(props){
        super(props);
        this.state = {
            selectedArticles: new Set(),
            selectedArticle: null,
            selectedCollectionIndex: SEARCH_RESULTS_INDEX,
        }
    }

    componentDidMount = () => {
        document.title = "COVID-SEE | Collections";
    }

    onDeleteComplete = () => {
        this.setState({ selectedArticles: new Set(), selectedArticle: null })
    }

    onSelectCollection = (index) => {

        if (index === this.state.selectedCollectionIndex) return;

        const { collections, createNewCollection } = this.props;
        if (index === collections.collections.length){
            createNewCollection(`Collection #${ index + 1 }`);
            return;
        }

        this.setState({ selectedCollectionIndex : index, selectedArticle: null, selectedArticles: new Set() });
    }

    onSelectArticle = (article) => {

        const { selectedArticles } = this.state;

        const newSelectedArticles = new Set(selectedArticles);

        if (newSelectedArticles.has(article)){
            newSelectedArticles.delete(article);
        } else{
            newSelectedArticles.add(article);
        }

        this.setState({ selectedArticles : newSelectedArticles });
    }

    onSelectAll = () => {

        const { collections, searchResults } = this.props.collections;
        const { selectedCollectionIndex } = this.state;

        let selectedCollection = [];
        if (selectedCollectionIndex === SEARCH_RESULTS_INDEX){
            selectedCollection = searchResults;
        } else{
            selectedCollection = collections[selectedCollectionIndex].articles;
        }

        this.setState({ selectedArticles : selectedCollection });
    }

    onUnselectAll = () => {
        this.setState({ selectedArticles: new Set() });
    }

    onToggleArticle = (article) => {

        const selectedArticle = this.state.selectedArticle;

        if (selectedArticle && article.cord_uid === selectedArticle.cord_uid){
            this.setState({ selectedArticle : null });
        } else {
            this.setState({ selectedArticle : article });
        }
    }

    onCloseArticle = () => {
        this.setState({ selectedArticle : null });
    }

    onCollectionNameChange = (collectionID, newName) => {
        const { changeCollectionName } = this.props;
        changeCollectionName(collectionID, newName);
    }

    onCollectionDelete = (collectionID) => {
        const { deleteCollection } = this.props;
        deleteCollection(collectionID);
        this.setState({ selectedCollectionIndex : SEARCH_RESULTS_INDEX, selectedArticle: null, selectedArticles: new Set() });
    }

    render(){

        const { collections, searchResults } = this.props.collections;
        const { selectedArticle, selectedArticles, selectedCollectionIndex } = this.state;

        let selectedCollection = [];

        if (selectedCollectionIndex === SEARCH_RESULTS_INDEX){
            selectedCollection = searchResults;
        } else{
            selectedCollection = collections[selectedCollectionIndex].articles;
        }

        const selectedCordUIDs = Array.from(selectedArticles).map(article => article.cord_uid);

        return (

            <div
                id="collections-page"
                className="page">

                <div
                    className="collections-container">

                    <div
                        className="info-1">

                        <div
                            className="panel">

                            <div
                                className="panel-title">
                                <p>Selection Manager</p>
                            </div>

                            <SelectionManager
                                totalNumArticles={ selectedCollection.length }
                                selectedCollectionIndex={ selectedCollectionIndex }
                                selectedArticles={ selectedArticles }
                                selectedCordUIDs={ selectedCordUIDs }
                                collections={ collections }
                                onDeleteComplete= { this.onDeleteComplete }/>
                        </div>

                        <div
                            className="panel">

                            <div
                                className="panel-title">
                                <p>Explorer</p>
                            </div>

                            <div
                                className="explorer">

                                <Collection
                                    collectionID={ null }
                                    name={ "Search Results" }
                                    index={ SEARCH_RESULTS_INDEX }
                                    numCollections={ collections.length }
                                    onSelect={ this.onSelectCollection }
                                    selected={ selectedCollectionIndex === SEARCH_RESULTS_INDEX }
                                    onDelete={ null }
                                    onNameChange={ null }/>

                                { collections.map((collection, index) => (

                                    <Collection
                                        key={ collection.collectionID }
                                        collectionID={ collection.collectionID }
                                        name={ collection.alias }
                                        index={ index }
                                        numCollections={ collections.length }
                                        onSelect={ this.onSelectCollection }
                                        selected={ selectedCollectionIndex === index}
                                        onDelete={ this.onCollectionDelete }
                                        onNameChange={ this.onCollectionNameChange }/>
                                ))}

                                <Collection
                                    collectionID={ null }
                                    name={ "+ New Collection"}
                                    index={ collections.length }
                                    numCollections={ collections.length }
                                    onSelect={ this.onSelectCollection }
                                    selected={ null }
                                    onDelete={ null }
                                    onNameChange={ null }/>
                            </div>

                        </div>

                    </div>

                    <div
                        className="info-2">

                        <div
                            className="panel">

                            <div
                                className="panel-title">
                                <p>Collection Viewer</p>
                            </div>

                            <div
                                className="collection-viewer">
                                { selectedCollection.map((article, i) =>
                                    <SearchResult
                                        key = { `${ article.cord_uid }-${ selectedCollectionIndex }-${ i }` }
                                        article = { article }
                                        onSelect = { this.onSelectArticle }
                                        inSelection = { selectedCordUIDs.includes(article.cord_uid) }
                                        expanded = { selectedArticle && selectedArticle.cord_uid === article.cord_uid }
                                        onToggleArticle = { this.onToggleArticle }/>
                                )}
                            </div>
                        </div>
                    </div>

                    <div
                        className="article-viewer">

                        { selectedArticle &&
                        <SelectedArticle
                            article={ selectedArticle }
                            onClose={ this.onCloseArticle }/>
                        }
                    </div>

                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    collections: state
});

const mapDispatchToProps = dispatch => {
    return {
        createNewCollection: (alias) => dispatch(createNewCollection(alias, [])),
        deleteCollection: (collectionID) => dispatch(deleteCollection(collectionID)),
        changeCollectionName: (collectionID, newName) => dispatch(changeCollectionName(collectionID, newName))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionsPage);
