import React, { useState, useRef } from "react";
import axios                       from "axios";
import { ReCaptcha }               from "react-recaptcha-v3";

import Spinner from "react-bootstrap/Spinner";
import Tabs    from "react-bootstrap/Tabs";
import Tab     from "react-bootstrap/Tab";

import "../../stylesheets/components/login.css";

const EMAIL_RE = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const MIN_PW_LEN = 8;

const LoginForm = ({ onLoginSuccess, onToggleMode }) => {

    const [fName, setFName] = useState("");
    const [lName, setLName] = useState("");
    const [loginEmail, setLoginEmail] = useState("");
    const [registerEmail, setRegisterEmail] = useState("");
    const [loginPw, setLoginPw] = useState("");
    const [registerPw, setRegisterPw] = useState("");
    const [pwc, setPwc] = useState("");

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [registeredEmail, setRegisteredEmail] = useState("");

    const reCaptcha = useRef();
    const [captchaToken, setcaptchaToken] = useState(false);

    const onLogin = (e) => {

        e.preventDefault();
        setRegisteredEmail("");
        setLoading(true);

        // clear current errors
        setErrors({});

        let errors_ = {};
        if (!EMAIL_RE.test(String(loginEmail).toLowerCase())) errors_["loginEmail"] = "Please enter a valid email address!";
        if (!loginPw) errors_["loginPw"] = "Please enter a password";

        if (Object.keys(errors_).length > 0){
            setErrors(errors_);
            setLoginPw("");
            setLoading(false);
            return;
        }

        axios("/login", {
            method: "post",
            data: {
                email: loginEmail,
                pw: loginPw
            }
        })
        .then(res => {
            let token = res.data.token;
            if (token){
                onLoginSuccess(token);
            } else{
                setErrors(res.data);
            }
            setLoading(false);
        })
        .catch(err => {
            setErrors({ "serverLogin" : "Sorry, something went wrong."});
            setLoading(false);
        });
    }

    const onRegister = (e) => {

        e.preventDefault();
        setRegisteredEmail("");
        setLoading(true);

        // clear current errors
        setErrors({});

        let errors_ = {};
        if (!fName) errors_["fName"] = "Please enter your first name!";
        if (!lName) errors_["lName"] = "Please enter your last name!";
        if (!EMAIL_RE.test(String(registerEmail).toLowerCase())) errors_["registerEmail"] = "Please enter a valid email address!";
        if (!registerPw) errors_["registerPw"] = "Please enter a password!";
        if (!pwc) errors["pwc"] = "Please confirm your password!";

        if (registerPw !== pwc){
            errors_["registerPw"] = "Passwords must match!";
            errors_["pwc"] = "Passwords must match!";
        }

        if (registerPw.length < MIN_PW_LEN){
            errors_["registerPw"] = `Passwords must be at least ${ MIN_PW_LEN } characters long!`;
            errors_["pwc"] = `Passwords must be at least ${ MIN_PW_LEN } characters long!`;
        }

        if (Object.keys(errors_).length > 0){
            setErrors(errors_);

            // reset passwords
            setRegisterPw("");
            setPwc("");
            setLoading(false);

            return;
        }

        if (!captchaToken){
            setErrors({ "serverRegister" : "Sorry, you've been identified as a bot!\nIn the event of this being an error, please email us at info@covid-see.com" });
            setLoading(false);
            return;
        }

        axios("/register", {
            method: "post",
            data: {
                fName: fName,
                lName: lName,
                email: registerEmail,
                pw: registerPw,
                pwc: pwc,
                captchaToken: captchaToken
            }
        })
        .then(response => {
            let res = response.data;

            if (res === ''){
                setRegisteredEmail(registerEmail);
            } else {
                if (res.hasOwnProperty("reCaptcha")){
                    res["serverRegister"] = res["reCaptcha"];
                    delete res["reCaptcha"];
                } else{
                    reCaptcha.current.execute();
                }
                setErrors(res);
            }
            setLoading(false);
        })
        .catch(_ => {
            setErrors({ "serverRegister" : "Sorry, something went wrong." });
            setLoading(false);
        });
    }

    return (

        <div
            className="login-panel registration-panel">

            <Tabs
                defaultActiveKey="Sign In">

                <Tab
                    eventKey="Sign In"
                    title="Sign In">

                    <form
                        onSubmit={ e => onLogin(e) }>

                        <label
                            htmlFor="loginEmail">
                            Email
                        </label>
                        <input
                            id="loginEmail"
                            className={ (errors.hasOwnProperty("loginEmail") || errors.hasOwnProperty("loginFail")) ? "error" : "" }
                            type="email"
                            required
                            onChange={ e => setLoginEmail(e.target.value) }
                            value={ loginEmail }/>
                        { (errors.hasOwnProperty("loginEmail") || errors.hasOwnProperty("loginFail")) &&
                        <p
                            className="error">
                            { errors.loginEmail }
                        </p>}

                        <label
                            htmlFor="loginPw">
                            Password
                        </label>
                        <input
                            id="loginPw"
                            className={ (errors.hasOwnProperty("loginPw") || errors.hasOwnProperty("loginFail")) ? "error" : "" }
                            type="password"
                            required
                            onChange={ e => setLoginPw(e.target.value) }
                            value={ loginPw }/>
                        { (errors.hasOwnProperty("loginPw") || errors.hasOwnProperty("loginFail") || errors.hasOwnProperty("serverLogin")) &&
                        <p
                            className="error">
                            { errors.loginPw || errors.loginFail || errors.serverLogin }
                        </p>}

                        <button
                            className="btn-reset"
                            type="button"
                            onClick={ () => onToggleMode() }>
                            Forgot your password?
                        </button>

                        <button
                            className="btn-blue btn-sign-in"
                            type="submit"
                            disabled={ loading }>
                            { loading
                            ? <Spinner
                                className="loading-spinner"
                                animation="border"
                                variant="light" />
                            : "Sign In" }
                        </button>
                    </form>
                </Tab>

                <Tab
                    eventKey="Sign Up"
                    title="Sign Up">

                    <form
                        onSubmit={ e => onRegister(e) }>

                        <label
                            htmlFor="fname">
                            First Name
                        </label>
                        <input
                            id="fname"
                            className={ errors.hasOwnProperty("fName") ? "error" : "" }
                            type="text"
                            required
                            onChange={ e => setFName(e.target.value) }
                            value={ fName }/>
                        { errors.hasOwnProperty("fName") &&
                        <p
                            className="error">
                            { errors.fName }
                        </p>}

                        <label
                            htmlFor="lname">
                            Last Name
                        </label>
                        <input
                            id="lname"
                            className={ errors.hasOwnProperty("lName") ? "error" : "" }
                            type="text"
                            required
                            onChange={ e => setLName(e.target.value) }
                            value={ lName }/>
                        { errors.hasOwnProperty("lName") &&
                        <p
                            className="error">
                            { errors.lName }
                        </p>}

                        <label
                            htmlFor="registerEmail">
                            Email
                        </label>
                        <input
                            id="registerEmail"
                            className={ (errors.hasOwnProperty("registerEmail") || errors.hasOwnProperty("verified")) ? "error" : "" }
                            type="email"
                            required
                            onChange={ e => setRegisterEmail(e.target.value) }
                            value={ registerEmail }/>
                        { (errors.hasOwnProperty("registerEmail") || errors.hasOwnProperty("verified")) &&
                        <p
                            className="error">
                            { errors.registerEmail || errors.verified }
                        </p>}

                        <label
                            htmlFor="registerPw">
                            Password
                        </label>
                        <input
                            id="registerPw"
                            className={ errors.hasOwnProperty("registerPw") ? "error" : "" }
                            type="password"
                            required
                            onChange={ e => setRegisterPw(e.target.value) }
                            value={ registerPw }
                            minLength={ MIN_PW_LEN }/>
                        { errors.hasOwnProperty("registerPw") &&
                        <p
                            className="error">
                            { errors.registerPw }
                        </p>}

                        <label
                            htmlFor="pwc">
                            Confirm Password
                        </label>
                        <input
                            id="pwc"
                            className={ errors.hasOwnProperty("pwc") ? "error" : "" }
                            type="password"
                            required
                            onChange={ e => setPwc(e.target.value) }
                            value={ pwc }
                            minLength={ MIN_PW_LEN }/>
                        { errors.hasOwnProperty("pwc") &&
                        <p
                            className="error">
                            { errors.pwc }
                        </p>}

                        { (errors.hasOwnProperty("serverRegister") || errors.hasOwnProperty("verification")) &&
                        <p
                            className="error">
                            { errors.serverRegister || errors.verification }
                        </p>}

                        <button
                            className="btn-blue btn-sign-in"
                            disabled={ loading }>
                            { loading
                            ? <Spinner
                                className="loading-spinner"
                                animation="border"
                                variant="light" />
                            : "Sign Up" }
                        </button>

                        { registeredEmail.length > 0 &&
                        <p
                            className="success">
                            Thanks for signing up! <br/> We've sent a verification link to { registeredEmail }.
                        </p>}

                        <ReCaptcha
                            ref={ reCaptcha }
                            sitekey="6Lcj7sAZAAAAABt11lfhgT_o1SFH6Z9Nn4JNSLBz"
                            verifyCallback={ res => setcaptchaToken(res) }/>
                    </form>
                </Tab>
            </Tabs>

        </div>
    )
}

export default LoginForm;
