import React from "react";

import "../../stylesheets/components/footer.css";

const Footer = () => {
    return (
        <div
            className="footer">
            &copy; 2020 COVID-SEE
        </div>
    )
}

export default Footer
