import React, { useState, useEffect } from "react";
import { connect }         from "react-redux";

import Modal       from "react-bootstrap/Modal";
import { CSVLink } from "react-csv";
import {
        updateCollection,
        createNewCollection } from "../../redux/actions/userActions";

import "../../stylesheets/components/selection-pane.css";

const SelectionPane = ({ articles, collections, cord_uids,
                         addArticlesToExistingCollection,
                         addArticlesToNewCollection }) => {

    const [showAddModal, setShowAddModal] = useState(false);
    const [target, setTarget] = useState(0);
    const [newName, setNewName] = useState(`Collection #${ collections.length + 1 }`)

    useEffect(() => {
        setNewName(`Collection #${ collections.length + 1 }`)
    }, [collections]);

    const setTargetIndex = (index) => {

        if (index < 0 || index > collections.length) return;

        setTarget(index);
    }

    const addToCollection = () => {

        if (target === collections.length){
            if (newName === ''){
                addArticlesToNewCollection(`Collection #${ collections.length + 1 }`, cord_uids);
            } else{
                addArticlesToNewCollection(newName, cord_uids);
            }
        } else{
            addArticlesToExistingCollection(collections[target].collectionID, cord_uids);
        }

        onHide();
    }

    const onHide = () => {
        setShowAddModal(false);
        setTarget(0);
    }

    return (

        <div
            className={ "selection-pane " + (articles.size === 0 ? "in-active" : "") }>

            <p>
                <b>{ articles.size }</b>
                &nbsp;
                { articles.size === 1 ? "Article" : "Articles" } Selected
            </p>

            <div
                className="options">

                <button
                    className="collect"
                    onClick={ () => setShowAddModal(true) }>
                    Collect
                </button>

                <Modal
                    key={ collections.length }
                    show={ showAddModal }
                    onHide={() => onHide() }
                    centered
                    dialogClassName="modal-90w"
                    aria-labelledby="collect-modal-title"
                    className="collect-modal">

                    <Modal.Header
                        closeButton>

                        <Modal.Title
                            id="collect-modal-title">
                            Collect Selected Articles
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        <div
                            className="action">

                            <label
                                htmlFor="targetSelector">
                                <b>Collection:&nbsp;</b>
                            </label>

                            <select
                                id="targetSelector"
                                onChange={ (e) => setTargetIndex(parseInt(e.target.value)) }>

                                { collections.map((collection, index) =>
                                <option
                                    key={ collection.collectionID }
                                    value={ index }>
                                    { collection.alias }
                                </option>
                                )}

                                <option
                                    value={ collections.length }>
                                    New Collection
                                </option>

                            </select>

                            { target === collections.length &&
                            <div>
                                <label
                                    htmlFor="new-name">
                                    <b>Name:</b>
                                </label>

                                <input
                                    type="text"
                                    placeholder={ `Collection #${ collections.length + 1 }` }
                                    onChange={ (e) => setNewName(e.target.value) }/>
                            </div>}
                        </div>

                        <button
                            className="collect"
                            onClick={ () => addToCollection()}>
                            Collect
                        </button>

                    </Modal.Body>
                </Modal>

                <button
                    className="export">

                    <CSVLink
                        data={ Array.from(articles) }
                        filename={ "export-" + new Date().toISOString() + ".csv" }>
                        Export
                    </CSVLink>

                </button>

            </div>

        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        addArticlesToExistingCollection: (collectionID, cord_uids) => dispatch(updateCollection(true, collectionID, cord_uids)),
        addArticlesToNewCollection: (alias, cord_uids) => dispatch(createNewCollection(alias, cord_uids))
    }
};

const mapStateToProps = (state) => ({
    collections: state.collections
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectionPane);
