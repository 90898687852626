import React, { useState, useEffect } from "react";
import axios                          from "axios";
import { NavLink }                    from 'react-router-dom';
import { connect }                    from "react-redux";
import jwtDecode                      from 'jwt-decode';

import { setUnAuthenticated, setAuthenticated } from "../../redux/actions/userActions";

import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown       from "react-bootstrap/Dropdown";
import Modal          from "react-bootstrap/Modal";

import logoDesktop from "../../covidsee_logo.png";
import logoMobile  from "../../covidsee_logo_2.png";

import LoginModal  from "./LoginModal";
import ContactForm from "./ContactForm";

import "../../stylesheets/components/navbar.css";

const MOBILE_WIDTH = 578;

const Navbar = ({ authenticated, userName, setAuthenticated, setUnAuthenticated }) => {

    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showContactModal, setShowContactModal] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [onDesktop, setOnDesktop] = useState(true);

    const onLogout = () => {
        localStorage.removeItem("covidSeeAuthToken");
        setUnAuthenticated();
        delete axios.defaults.headers.common['Authorization'];
    }

    const onLogin = (token) => {
        localStorage.setItem("covidSeeAuthToken", token);
        setAuthenticated(jwtDecode(token).name);
        axios.defaults.headers.common['Authorization'] = `Bearer ${ token }`;
        setShowLoginModal(false);
    }

    useEffect(() => {
        if (parseInt(window.innerWidth) < MOBILE_WIDTH){
            setOnDesktop(false);
        }
    }, [])

    return (
        <nav
            className="navbar-container">

            <div
                className="navbar-logo desktop">
                <NavLink
                    to="/search">
                    <img
                        src={ logoDesktop }
                        alt="navbar logo"
                        width="176px"
                        height="60px"/>
                </NavLink>
            </div>

            <div
                className="navbar-logo mobile">
                <NavLink
                    to="/search">
                    <img
                        src={ logoMobile }
                        alt="navbar logo"
                        width="60px"
                        height="60px"/>
                </NavLink>
            </div>

            { onDesktop
            ? <div
                className="navbar-navlinks desktop">
                <ul>
                    <li>
                        <NavLink
                            className="navbar-navlink"
                            activeClassName="navbar-navlink--active"
                            to="/about">
                            About
                        </NavLink>
                    </li>
                    <li>
                        <DropdownButton
                            id="dropdown-basic-button"
                            title="Search">
                            <Dropdown.Item
                                as="button">
                                <NavLink
                                    className="navbar-navlink"
                                    activeClassName="navbar-navlink--active"
                                    to="/search">
                                    IR Search
                                </NavLink>
                            </Dropdown.Item>
                            <Dropdown.Item
                                as="button">
                                <NavLink
                                    className="navbar-navlink"
                                    activeClassName="navbar-navlink--active"
                                    to="/structured-search">
                                    Structured Search
                                </NavLink>
                            </Dropdown.Item>
                        </DropdownButton>
                    </li>
                    <li>
                        <NavLink
                            className="navbar-navlink"
                            activeClassName="navbar-navlink--active"
                            to="/collections">
                            Collections
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className="navbar-navlink"
                            activeClassName="navbar-navlink--active"
                            to="/visualiser">
                            Visualiser
                        </NavLink>
                    </li>
                </ul>
                <ul>
                    <li>

                        { authenticated
                        ? <DropdownButton
                            id="dropdown-basic-button"
                            title={ userName }>
                            <Dropdown.Item
                                as="button"
                                onClick={ () => onLogout() }>
                                Logout
                            </Dropdown.Item>
                        </DropdownButton>
                        : <button
                            className="btn-blue"
                            onClick={ () => setShowLoginModal(true) }>
                            Login
                        </button>}

                        <LoginModal
                            show={ showLoginModal }
                            close={ () => setShowLoginModal(false) }
                            onLogin={ onLogin }/>
                    </li>
                    <li>
                        <button
                            className="btn-contact"
                            onClick={ () => setShowContactModal(true) }>
                            Contact
                        </button>
                        <Modal
                            show={ showContactModal }
                            size="md"
                            onHide={ () => setShowContactModal(false) }
                            centered
                            dialogClassName="modal-90w"
                            aria-labelledby="contact-modal-title"
                            className="contact-modal">

                            <Modal.Header
                                closeButton>
                                <Modal.Title
                                    id="contact-modal-title">
                                    Contact Us
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <ContactForm
                                    isLoggedIn={ authenticated }/>
                            </Modal.Body>

                        </Modal>
                    </li>
                </ul>
            </div>

            : <div
                className={ `navbar-navlinks mobile ${ showMenu ? "open" : "closed" }` }>
                <ul>
                    <li>
                        <NavLink
                            className="navbar-navlink"
                            activeClassName="navbar-navlink--active"
                            to="/about"
                            onClick={ () => setShowMenu(false) }>
                            About
                        </NavLink>
                    </li>
                    <li>
                        <DropdownButton
                            id="dropdown-basic-button"
                            title="Search">
                            <Dropdown.Item
                                as="button">
                                <NavLink
                                    className="navbar-navlink"
                                    activeClassName="navbar-navlink--active"
                                    to="/search"
                                    onClick={ () => setShowMenu(false) }>
                                    IR Search
                                </NavLink>
                            </Dropdown.Item>
                            <Dropdown.Item
                                as="button">
                                <NavLink
                                    className="navbar-navlink"
                                    activeClassName="navbar-navlink--active"
                                    to="/structured-search"
                                    onClick={ () => setShowMenu(false) }>
                                    Structured Search
                                </NavLink>
                            </Dropdown.Item>
                        </DropdownButton>
                    </li>

                    <li>

                        { authenticated
                        ? <DropdownButton
                            id="dropdown-basic-button"
                            title={ `Hi ${ userName }` }>
                            <Dropdown.Item
                                as="button"
                                onClick={ () => onLogout() }>
                                Logout
                            </Dropdown.Item>
                        </DropdownButton>
                        : <button
                            className="btn-blue"
                            onClick={ () => setShowLoginModal(true) }>
                            Login
                        </button>}

                        <LoginModal
                            show={ showLoginModal }
                            close={ () => setShowLoginModal(false) }
                            onLogin={ onLogin }/>
                    </li>
                    <li>
                        <button
                            className="btn-contact"
                            onClick={ () => setShowContactModal(true) }>
                            Contact
                        </button>
                        <Modal
                            show={ showContactModal }
                            size="md"
                            onHide={ () => setShowContactModal(false) }
                            centered
                            dialogClassName="modal-90w"
                            aria-labelledby="contact-modal-title"
                            className="contact-modal">

                            <Modal.Header
                                closeButton>
                                <Modal.Title
                                    id="contact-modal-title">
                                    Contact Us
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <ContactForm
                                    isLoggedIn={ authenticated }/>
                            </Modal.Body>

                        </Modal>
                    </li>
                </ul>
            </div>}

            <button
                className={ `toggle-btn ${ showMenu ? "open" : "closed" }` }
                onClick={ () => setShowMenu(!showMenu) }>
                <div
                    className="line">
                </div>
            </button>
        </nav>
    )
}

const mapDispatchToProps = dispatch => {
	return {
        setAuthenticated: (name) => dispatch(setAuthenticated(name)),
		setUnAuthenticated: () => dispatch(setUnAuthenticated())
	};
};

const mapStateToProps = (state) => ({
    authenticated: state.authenticated,
    userName: state.userName
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
