import React, { Component } from "react";
import { connect }          from "react-redux";
import axios                from "axios";

import Particles from "react-particles-js";

import SearchResults           from "../components/Search/SearchResults";
import IRSearchBar             from "../components/Search/IRSearchBar";

import "../stylesheets/pages/search.css";

// data
import PARTICLES_CONFIG from "../data/particlesjs-config.json";

import { overwriteSearchResults } from "../redux/actions/userActions";

class SearchPage extends Component{

	constructor(props){
		super(props);

		const queryContainer = new URLSearchParams(this.props.location.search);

		const query = queryContainer.get("query");

		this.state = {
			query: query,
			loading: true,
			results_data: [[], []],
			error: false
		}
	}

	componentDidMount(){

		const { query } = this.state;

		document.title = "COVID-SEE | Search";

		if (query === null || query === undefined){
			this.setState({
				loading: false,
				error: false
			});
			return;
		}

		document.title = `${ query } - COVID-SEE | Search`;

		setTimeout(() => {

			axios("/search", {
					method: "post",
					data : {
						query: query,
						isIR: true
					},
					withCredentials: true,
					'Access-Control-Allow-Credentials': true
				})
				.then(res => {
					this.setState({
						results_data: res.data,
						loading: false,
						error: false });
					this.props.overwriteSearchResults(res.data[0]);
				})
				.catch(e => {
					this.setState({ error: true });
				})
		}, 2500); // delay needed to get correct search results
	}

	onSearchHandler = (queryString) => {
		this.props.history.push(`/search?query=${ queryString }`);
		window.location.reload();
	}

	render(){

		const { loading, results_data, query, error } = this.state;

		return (

			<div>

				{ (query === null || query === undefined) &&
					<Particles
						params={ PARTICLES_CONFIG }/>}

				<div
					id="search-page"
					className="page">

					<IRSearchBar
						onSearchHandler={ this.onSearchHandler }
						initQuery={ query }/>

					{ query !== null && query !== undefined &&
					<SearchResults
						loading={ loading }
						results_data={ results_data }
						error={ error }/>}

				</div>

			</div>
		)
	}
}

const mapDispatchToProps = dispatch => {
	return {
		overwriteSearchResults: (results) => dispatch(overwriteSearchResults(results))
	};
};

export default connect(null, mapDispatchToProps)(SearchPage);
