// user reducer types
export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";
export const ADD_COLLECTION = "ADD_COLLECTION";
export const UPDATE_COLLECTION = "UPDATE_COLLECTION";
export const DEL_COLLECTION = "DEL_COLLECTION";
export const CHANGE_COLLECTION_NAME = "CHANGE_COLLECTION_NAME"
export const SET_USER_DATA = "SET_USER_DATA";
export const ADD_TO_COLLECTION = "ADD_TO_COLLECTION";
export const REMOVE_FROM_COLLECTION = "REMOVE_FROM_COLLECTION";
export const UPDATE_SEARCH_RESULTS = "UPDATE_SEARCH_RESULTS";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";

