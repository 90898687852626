import React from "react";

import "../../stylesheets/components/search-filter.css";

const getYears = (yearRange) => {
    if (!(yearRange.includes('-')))
        return [parseInt(yearRange)];
    let years = []
    const [start, end] = yearRange.split('-');
    for (let year=parseInt(start); year<=parseInt(end); year++){
        years.push(year);
    }
    return years;
}

const SearchFilter = ({ filter_info, onAddFilter, onResetFilters, loading }) =>{

    return (

        <div
            className={ "search-filter " + (loading ? "loading" : "") }>

            { !loading &&
            <h3
                className="filter-label">
                Filter
            </h3>}

            { !loading &&
            <ul
                className="filter-categories">

                <li
                    className="filter-category">
                    <span
                        className="category-label">
                        Year Published:
                    </span>
                    <ul className="filter-options">
                        { Object.keys(filter_info.publish_years).sort().map((yearRange, index) => (
                            <li
                                key={`${ yearRange }-${ index }`}
                                className="filter-option">
                                <input
                                    type="checkbox"
                                    className="option-btn"
                                    onChange={ () => onAddFilter("publish_time", getYears(yearRange))}>
                                </input>
                                <span
                                    className="option-label">
                                { `${ yearRange } (${ filter_info.publish_years[yearRange] })` }
                                </span>
                            </li>
                        ))}
                    </ul>
                </li>

                <li
                    className="filter-category">
                    <span
                        className="category-label">
                        Source:
                    </span>
                    <ul className="filter-options">
                        { Object.keys(filter_info.sources).map((source, index) => (
                            <li
                                key={`${ source }-${ index }`}
                                className="filter-option">
                                <input
                                    type="checkbox"
                                    className="option-btn"
                                    onChange={ () => onAddFilter("source_x", [source])}>
                                </input>
                                <span
                                    className="option-label">
                                { `${ source } (${ filter_info.sources[source] })` }
                                </span>
                            </li>
                        ))}
                    </ul>
                </li>
            </ul>}

            { !loading &&
            <button
                className="reset-btn"
                onClick={ () => onResetFilters() }>
                Clear All
            </button>}
        </div>
    )
}

export default SearchFilter;