import React, { useState, useEffect } from "react";
import axios                          from "axios";

import Spinner from "react-bootstrap/Spinner";

import "../stylesheets/pages/reset-pw.css";

const MIN_PW_LEN = 8;

const ResetPwPage = (props) => {

    const [loadingPage, setLoadingPage] = useState(true);
    const [loadingRes, setLoadingRes] = useState(false);
    const [errors, setErrors] = useState("");
    const [id, setId] = useState(null);

    const [pw, setPw] = useState("");
    const [pwc, setPwc] = useState("");
    const [newPwConfirmed, setNewPwConfirmed] = useState(false);

    useEffect(() => {

        const query = new URLSearchParams(props.location.search);
        const id_ = query.get("id");
        setId(id_);

        if (id_ === undefined || id_ === null){
            setErrors({ "server" : "Sorry, this link appears to be invalid" });
            return;
        }

        axios("/verify_reset_request", {
            method: "post",
            data : {
                encrypted_url: id_
            },
            withCredentials: true,
            'Access-Control-Allow-Credentials': true
        })
        .then(response => {
            let res = response.data;
            if (res !== ''){
                setErrors(res);
            }
            setLoadingPage(false);
        })
        .catch(e => {
            setErrors({ "server" : "Sorry, something went wrong" });
            setLoadingPage(false);
        })
    }, [props.location.search]);

    const onResetPassword = (e) => {

        e.preventDefault();
        setLoadingRes(true);
        setNewPwConfirmed(false);

        // clear current errors
        setErrors({});

        let errors_ = {};
        if (!pw) errors_["pww"] = "Please enter a password!";
        if (!pwc) errors["pwc"] = "Please confirm your password!";

        if (pw !== pwc){
            errors_["pww"] = "Passwords must match!";
            errors_["pwc"] = "Passwords must match!";
        }

        if (pw.length < MIN_PW_LEN){
            errors_["pww"] = `Passwords must be at least ${ MIN_PW_LEN } characters long!`;
            errors_["pwc"] = `Passwords must be at least ${ MIN_PW_LEN } characters long!`;
        }

        if (Object.keys(errors_).length > 0){
            setErrors(errors_);

            // reset passwords
            setPw("");
            setPwc("");
            setLoadingRes(false);

            return;
        }

        axios("/reset_pw_confirm", {
            method: "post",
            data: {
                encrypted_url: id,
                pw: pw,
                pwc: pwc
            }
        })
        .then(response => {
            let res = response.data;

            if (res === ''){
                setNewPwConfirmed(true);
            } else {
                setErrors(res);
            }
            setLoadingRes(false);
        })
        .catch(err => {
            setErrors({ "server" : "Sorry, something went wrong." });
            setLoadingRes(false);
        });
    }

    return (

        <div
			id="reset-pw-page"
			className="page">

            <div
                className="pw-reset-form">

                <div
                    className="header">

                    <h2>
                        Reset Password
                    </h2>
                </div>

                { loadingPage
                ? <Spinner
                    animation="border"
                    variant="dark" />
                : errors.hasOwnProperty("link")
                    ? <p
                        className="error link-verification">
                        { errors.link }
                    </p>
                    : <form
                        className="reset-pw-form"
                        onSubmit={ e => onResetPassword(e) }>
                        <label
                            htmlFor="pw">
                            New Password
                        </label>
                        <input
                            id="pw"
                            className={ errors.hasOwnProperty("pw") ? "error" : "" }
                            type="password"
                            required
                            onChange={ e => setPw(e.target.value) }
                            value={ pw }
                            minLength={ MIN_PW_LEN }/>
                        { errors.hasOwnProperty("pw") &&
                        <p
                            className="error">
                            { errors.pw }
                        </p>}

                        <label
                            htmlFor="pwc">
                            Confirm New Password
                        </label>
                        <input
                            id="pwc"
                            className={ errors.hasOwnProperty("pwc") ? "error" : "" }
                            type="password"
                            required
                            onChange={ e => setPwc(e.target.value) }
                            value={ pwc }
                            minLength={ MIN_PW_LEN }/>
                        { (errors.hasOwnProperty("pwc") || errors.hasOwnProperty("link") || errors.hasOwnProperty("server")) &&
                        <p
                            className="error">
                            { errors.pwc || errors.link || errors.server }
                        </p>}

                        <button
                            className="btn-blue btn-sign-in"
                            disabled={ loadingRes }>
                            { loadingRes
                            ? <Spinner
                                className="loadingRes-spinner"
                                animation="border"
                                variant="light" />
                            : "Submit" }
                        </button>

                        { Object.keys(errors).length === 0 && newPwConfirmed &&
                        <p
                            className="success">
                            Your password has successfully been updated!
                        </p>}

                    </form>}
            </div>
        </div>
    )
}

export default ResetPwPage;
