import React, { useState } from "react";

import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

import Modal from "react-bootstrap/Modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInbox }     from "@fortawesome/free-solid-svg-icons";

import "../../stylesheets/components/collection.css";

const SEARCH_RESULTS_INDEX = -1;

const Collection = ({ collectionID, name, index, numCollections, onSelect, selected, onDelete, onNameChange }) => {

    const creator = index === numCollections;
    const [showModal, setShowModal] = useState(false);
    const [newName, setNewName] = useState(name);

    const _onNameChange = () => {

        if (newName === "" || newName === name) return;

        onNameChange(collectionID, newName);
        setShowModal(false);
    }

    return (

        <div>

            <ContextMenuTrigger
                id={ `collection-context-menu-${ index }` }>

                <div
                    className={ `collection ${ selected ? "active" : "" } ${ creator ? "creator" : "" }` }
                    onClick={ (e) => onSelect(index) }>

                    <FontAwesomeIcon
                        icon={ faInbox }
                        className="collection-icon"/>
                    <p
                        className="collection-name">
                        { name }
                    </p>

                </div>

            </ContextMenuTrigger>

            { index !== SEARCH_RESULTS_INDEX && !creator &&
            <ContextMenu
                id={ `collection-context-menu-${ index }` }>

                <MenuItem
                    className="rename"
                    onClick={ () => setShowModal(true) }>
                    Rename
                </MenuItem>

                <MenuItem
                    className="delete"
                    onClick={ () => onDelete(collectionID) }>
                    Delete
                </MenuItem>
            </ContextMenu>}

            <Modal
                show={ showModal }
                onHide={() => setShowModal(false) }
                centered
                dialogClassName="modal-90w"
                aria-labelledby="rename-modal-title"
                className="move-modal">

                <Modal.Header
                    closeButton>

                    <Modal.Title
                        id="rename-modal-title">
                        Rename Collection
                    </Modal.Title>

                </Modal.Header>

                <Modal.Body>

                    <p>
                        <b>Current Name:</b> { name }
                    </p>

                    <label
                        for="new-name">
                        <b>New Name:</b>
                    </label>

                    <input
                        type="text"
                        onChange={ (e) => setNewName(e.target.value) }/>

                    <button
                        className="move"
                        onClick={ () => _onNameChange() }>
                        Confirm
                    </button>

                </Modal.Body>

            </Modal>

        </div>
    )
}

export default Collection;
