import {
    ADD_COLLECTION,
    UPDATE_COLLECTION,
    DEL_COLLECTION,
    SET_USER_DATA,
    UPDATE_SEARCH_RESULTS,
    CHANGE_COLLECTION_NAME,
    SET_AUTHENTICATED,
    SET_UNAUTHENTICATED
} from '../types';

/**
 * Stores and updates global state information regarding a user.
 */
export default function(state, action){

    let updatedCollections;

    switch(action.type){

        case SET_AUTHENTICATED:
            return {
                ...state,
                authenticated: true,
                userName: action.payload
            }

        case SET_UNAUTHENTICATED:
            return {
                ...state,
                authenticated: false,
                userName: null
            }

        case SET_USER_DATA:
            return {
                ...state,
                ...action.payload
            }

        case ADD_COLLECTION:

            let newCollection = action.payload;
            updatedCollections = [...state.collections];
            updatedCollections.push(newCollection);

            return {
                ...state,
                collections: updatedCollections
            }

        case UPDATE_COLLECTION:

            let updatedCollection = action.payload;
            updatedCollections = [...state.collections];
            let indexToUpdate = updatedCollections.findIndex(collection => collection.collectionID === updatedCollection.collectionID);
            if (indexToUpdate >= 0) updatedCollections[indexToUpdate] = updatedCollection;

            return {
                ...state,
                collections: updatedCollections
            }

        case CHANGE_COLLECTION_NAME:

            let { collectionID, newName } = action.payload;
            updatedCollections = [...state.collections];
            for (let i=0; i<updatedCollections.length; i++){
                if (updatedCollections[i].collectionID === collectionID){
                    updatedCollections[i].alias = newName;
                    break;
                }
            }

            return {
                ...state,
                collections: updatedCollections
            }

        case DEL_COLLECTION:

            let oldCollectionID = action.payload;
            updatedCollections = [...state.collections];
            let indexToRemove = updatedCollections.findIndex(collection => collection.collectionID === oldCollectionID);
            updatedCollections.splice(indexToRemove, 1);

            return {
                ...state,
                collections: updatedCollections
            }

        case UPDATE_SEARCH_RESULTS:

            return {
                ...state,
                searchResults: action.payload
            }

        default:
            return state;
    }
}