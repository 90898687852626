import React, { useEffect, useState }  from "react";
import { connect } from "react-redux";
import axios                           from "axios";

import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faFile as fasFile } from "@fortawesome/free-solid-svg-icons";
import { faFile }            from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon }           from "@fortawesome/react-fontawesome";

import ReactWordcloud from "react-wordcloud";

import Spinner from "react-bootstrap/Spinner";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip        from "react-bootstrap/Tooltip";
import Modal          from "react-bootstrap/Modal";

import OverlayTriggerIcon   from "../Utilities/OverlayTriggerIcon";
import { updateCollection } from "../../redux/actions/userActions";

import "./../../stylesheets/components/word-cloud-viewer.css";

const options = {
    colors: ["#094183"],
    enableTooltip: true,
    deterministic: true,
    fontFamily: "Noto Serif",
    fontSizes: [20, 75],
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 3,
    rotations: 3,
    rotationAngles: [0],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1000,
};

const WordCloudViewer = ({ cord_uids, collectionInFocus,
                            addArticleToCollection, deleteArticleFromCollection
                         }) => {

    const [currIndex, setCurrIndex] = useState(0);
    const [wordClouds, setWordClouds] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {

        setLoading(true);

        axios("/word_cloud_data", {
                method: "post",
                data: {
                    cord_uids: JSON.stringify(cord_uids)
                }
            })
            .then(res => {
                setWordClouds(res.data);
                setLoading(false);
            })
            .catch(err => {
                setWordClouds([]);
                setLoading(false);
            });
    }, []);

    let collectButton = null;
    let focusedArticle = null;
    if (collectionInFocus !== undefined && wordClouds.length > 0){
        const { collectionID, alias, articles } = collectionInFocus;
        const focused_cord_uids = new Set(articles.map(a => a.cord_uid));
        const article = wordClouds[currIndex].article;
        const { cord_uid, title, url, abstract, authors, journal, publish_time } = article;

        const add_ = (cord_uid_) => addArticleToCollection(collectionID, cord_uid_);
        const del_ = (cord_uid_) => deleteArticleFromCollection(collectionID, cord_uid_);

        let inCollection = focused_cord_uids.has(cord_uid);

        collectButton = (
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip>
                        { (inCollection ? "Remove from " : "Add to ") + alias }
                    </Tooltip>
                }>

                <button
                    className="cloud-changer-btn"
                    onClick={ () => inCollection ? del_(cord_uid) : add_(cord_uid) }>
                    <FontAwesomeIcon
                        icon={ inCollection ? fasFile : faFile }
                        className={ "nav-icon " + (inCollection ? "remove-article-icon" : "add-article-icon") }/>
                </button>

            </OverlayTrigger>);

        focusedArticle = (
            <div
                className="search-result related-article">

                { alias !== undefined &&
                <OverlayTriggerIcon
                    placement="bottom"
                    overlay_text={ (inCollection ? "Remove from " : "Add to ") + alias }
                    action={ inCollection ? () => del_(cord_uid) : () => add_(cord_uid) }
                    css_class={ inCollection ? "remove-article-icon" : "add-article-icon" }
                    icon={ inCollection ? fasFile : faFile }/>}

                <div
                    className="result-title">
                    <a
                        href={ url[0] }
                        target="_blank"
                        className="result-title"
                        rel="noopener noreferrer">
                        { title }
                    </a>
                </div>

                <div
                    className="meta-info">
                    <div
                        className="meta-field">
                        <div
                            className="field-title">
                            Authors:
                        </div>
                        <div
                            className="field-info">
                            { authors }
                        </div>
                    </div>

                    <div
                        className="meta-field">
                        <div
                            className="field-title">
                            Journal:
                        </div>
                        <div
                            className="field-info">
                            { `${ journal }, ${ publish_time }` }
                        </div>
                    </div>
                </div>

                <p
                    className= "result-abstract">
                    { abstract === "N/A" ? "" : abstract.replace(/^(.{300}[^\s]*).*/, "$1") + "..." }
                </p>

            </div>
        )
    }

    let wordCloudViewer =

        wordClouds.length > 0

        ? <div
            className="word-cloud-container">

            <div
                className="word-cloud-info">

                <p
                    className="field-label">
                    Current Article:
                </p>

                <button
                    className="article-title"
                    onClick={ () => setShowModal(true) }>
                    { wordClouds[currIndex].article.title }
                </button>

                <div
                    className="navigation-btns">
                    <button
                        className={ `cloud-changer-btn ${ currIndex === 0 ? "disabled" : "" }` }
                        disabled={ currIndex === 0 }
                        onClick={ () => setCurrIndex(currIndex - 1) }>
                        <FontAwesomeIcon
                            icon={ faAngleLeft }
                            className={ "nav-icon" }/>
                    </button>

                    { collectButton }

                    <button
                        className={ `cloud-changer-btn ${ currIndex === wordClouds.length - 1 ? "disabled" : "" }` }
                        disabled={ currIndex === wordClouds.length - 1 }
                        onClick={ () => setCurrIndex(currIndex + 1) }>
                            <FontAwesomeIcon
                                icon={ faAngleRight }
                                className={ "nav-icon" }/>
                    </button>
                </div>

                <Modal
                    show={ showModal }
                    onHide={ () => setShowModal(false) }
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    scrollable
                    className="word-cloud-modal">

                <Modal.Header
                    closeButton>
                </Modal.Header>

                <Modal.Body
                    closeButton>

                    { focusedArticle }

                </Modal.Body>

                </Modal>

            </div>

            <ReactWordcloud
                options={options}
                words={wordClouds[currIndex].word_cloud} />

        </div>

        : <h1
            className="diagram-fallback-msg">
            Sorry, something went wrong :(
        </h1>

    return loading

        ?	<Spinner
                className="loading-spinner"
                animation="border"
                variant="dark" />

        :   ( wordCloudViewer )
}

const mapDispatchToProps = dispatch => {
    return {
        addArticleToCollection: (collectionID, cord_uid) => dispatch(updateCollection(true, collectionID, [cord_uid])),
        deleteArticleFromCollection: (collectionID, cord_uid) => dispatch(updateCollection(false, collectionID, [cord_uid])),
    }
};

export default connect(null, mapDispatchToProps)(WordCloudViewer);