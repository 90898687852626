import React from "react";
import OverlayTriggerIcon from "../Utilities/OverlayTriggerIcon";

import {
    faTimes
}                          from "@fortawesome/free-solid-svg-icons";

import "./../../stylesheets/components/selected-article.css";

const SelectedArticle = ({ article, onClose }) => {

    const { journal, title, authors, abstract, url, publish_time, source_x, license } = article;

    return (
        <div
            className="selected-article">

            <OverlayTriggerIcon
                placement="bottom"
                overlay_text="close"
                action={ onClose }
                css_class="remove-icon"
                icon= { faTimes }/>

            <div
                className="article-title">
                <a
                    href={ url[0] }
                    target="_blank"
                    className="article-title"
                    rel="noopener noreferrer">
                    { title }
                </a>
            </div>

            <div
                className="meta-info">
                    <div
                        className="meta-field">
                        <div
                            className="field-title">
                            Authors:
                        </div>
                        <div
                            className="field-info">
                            { authors }
                        </div>
                    </div>

                    <div
                        className="meta-field">

                        <div
                            className="field-title">
                            Journal:
                        </div>
                        <div
                            className="field-info">
                            { journal }
                        </div>

                    </div>

                    <div
                        className="meta-field">

                        <div
                            className="field-title">
                            Source:
                        </div>
                        <div
                            className="field-info">
                            { source_x.join("; ") }
                        </div>

                    </div>

                    <div
                        className="meta-field">

                        <div
                            className="field-title">
                            License:
                        </div>
                        <div
                            className="field-info">
                            { license }
                        </div>

                    </div>

                    <div
                        className="meta-field">

                        <div
                            className="field-title">
                            Year:
                        </div>
                        <div
                            className="field-info">
                            { publish_time }
                        </div>

                    </div>
                </div>

                    <p
                        className="article-abstract">
                        { abstract}
                    </p>


        </div>
    )
}

export default SelectedArticle
