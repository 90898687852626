import React from "react";

import "../../stylesheets/components/search-result.css";
import "../../stylesheets/components/related-article.css";

import { faFile as fasFile } from "@fortawesome/free-solid-svg-icons";
import { faFile }            from "@fortawesome/free-regular-svg-icons";

import OverlayTriggerIcon from "./OverlayTriggerIcon";

const RelatedArticle = ({ article, collectionName, inCollection, addToCol, delFromCol }) => {

    const { cord_uid, journal, title, authors, abstract, url, publish_time } = article

    const add_ = () => addToCol(cord_uid);
    const del_ = () => delFromCol(cord_uid);

    return (
        <div
            className="search-result related-article">

            { collectionName !== undefined &&
            <OverlayTriggerIcon
                placement="bottom"
                overlay_text={ (inCollection ? "Remove from " : "Add to ") + collectionName}
                action={ inCollection ? del_ : add_ }
                css_class={ inCollection ? "remove-article-icon" : "add-article-icon" }
                icon={ inCollection ? fasFile : faFile }/>}

            <div
                className="result-title">
                <a
                    href={ url[0] }
                    target="_blank"
                    className="result-title"
                    rel="noopener noreferrer">
                    { title }
                </a>
            </div>

            <div
                className="meta-info">
                <div
                    className="meta-field">
                    <div
                        className="field-title">
                        Authors:
                    </div>
                    <div
                        className="field-info">
                        { authors }
                    </div>
                </div>

                <div
                    className="meta-field">
                    <div
                        className="field-title">
                        Journal:
                    </div>
                    <div
                        className="field-info">
                        { `${ journal }, ${ publish_time }` }
                    </div>
                </div>
            </div>

            <p
                className= "result-abstract">
                { abstract === "N/A" ? "" : abstract.replace(/^(.{300}[^\s]*).*/, "$1") + "..." }
            </p>

            {/* <div
                className="info">

                <div
                    className="meta-field">
                    <div
                        className="field-title">
                        Currently stored in:
                    </div>
                    <div
                        className="field-info">
                        { briefcases.filter(b => b.cord_uids.includes(cord_uid)).map(b => b.alias).join(", ") }
                    </div>
                </div>

            </div> */}

        </div>
    )
}

export default RelatedArticle;