import React, { useState, useRef } from "react";
import axios                       from "axios";
import { ReCaptcha }               from "react-recaptcha-v3";

import Spinner from "react-bootstrap/Spinner";

import "../../stylesheets/components/contact.css";

const EMAIL_RE = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const MAX_SUBJECT_LEN = 40;

const ContactForm = ({ isLoggedIn }) => {

    const [name, setName]       = useState("");
    const [email, setEmail]     = useState("");
    const [subject, setSubject] = useState("");
    const [msg, setMsg]         = useState("");
    const [success, setSuccess] = useState(false);

    const [loading, setLoading] = useState(false);
    const [errors, setErrors]   = useState({});

    const reCaptcha = useRef();
    const [captchaToken, setcaptchaToken] = useState(false);

    const onSend = (e) => {

        e.preventDefault();
        setSuccess(false);
        setLoading(true);

        // clear current errors
        setErrors({});

        let errors_ = {};
        if (!isLoggedIn){
            if (!name) errors_["name"] = "Please enter a name!";
            if (!EMAIL_RE.test(String(email).toLowerCase())) errors_["email"] = "Please enter a valid email address!";
        }
        if (!subject) errors_["subject"] = "Please enter a subject!";
        if (subject.length > MAX_SUBJECT_LEN) errors_["subject"] = `The subject must not exceed ${ MAX_SUBJECT_LEN } characters!`;
        if (!msg) errors_["msg"] = "Please enter a message!";

        if (Object.keys(errors_).length > 0){
            setErrors(errors_);
            setLoading(false);
            return;
        }

        if (!captchaToken){
            setErrors({ "server" : "Error: You've been identified as a bot." });
            setLoading(false);
            return;
        }

        axios("/contact", {
            method: "post",
            data: {
                name: name,
                email: email,
                subject: subject,
                msg: msg,
                captchaToken: captchaToken
            }
        })
        .then(response => {
            let res = response.data;
            if (res !== null){
                if (res.hasOwnProperty("reCaptcha")){
                    res["server"] = res["reCaptcha"];
                    delete res["reCaptcha"];
                } else{
                    reCaptcha.current.execute();
                }
                setErrors(res);
            } else{
                setSuccess(true);
            }
            setLoading(false);
        })
        .catch(err => {
            setErrors({ "server" : "Sorry, something went wrong."});
            setLoading(false);
        });

    }

    return (

        <div
            className="contact-form">

            <form
                onSubmit={ e => onSend(e) }>

                { !isLoggedIn &&

                <>
                    <label
                        htmlFor="name">
                        Name
                    </label>
                    <input
                        id="name"
                        className={ errors.hasOwnProperty("name") ? "error" : "" }
                        type="text"
                        required
                        onChange={ e => setName(e.target.value) }
                        value={ name }/>
                    { errors.hasOwnProperty("name") &&
                        <p
                            className="error">
                            { errors.name }
                        </p>}

                    <label
                        htmlFor="email">
                        Email
                    </label>
                    <input
                        id="email"
                        className={ errors.hasOwnProperty("email") ? "error" : "" }
                        type="email"
                        required
                        onChange={ e => setEmail(e.target.value) }
                        value={ email }/>
                    { errors.hasOwnProperty("email") &&
                    <p
                        className="error">
                        { errors.email }
                    </p>}
                </>}

                <label
                    htmlFor="subject">
                    Subject
                </label>
                <input
                    id="subject"
                    className={ errors.hasOwnProperty("subject") ? "error" : "" }
                    required
                    onChange={ e => setSubject(e.target.value) }
                    value={ subject }
                    maxLength={ MAX_SUBJECT_LEN }/>
                { errors.hasOwnProperty("subject") &&
                <p
                    className="error">
                    { errors.subject }
                </p>}

                <label
                    htmlFor="msg">
                    Message
                </label>
                <textarea
                    id="msg"
                    className={ errors.hasOwnProperty("msg") ? "error" : "" }
                    required
                    onChange={ e => setMsg(e.target.value) }
                    value={ msg }
                    placeholder="Type your message here"
                    rows={ 5 }/>
                { errors.hasOwnProperty("msg") &&
                <p
                    className="error">
                    { errors.msg }
                </p>}
                { errors.hasOwnProperty("server") &&
                <p
                    className="error">
                    { errors.server }
                </p>}

                <button
                    className="btn-blue"
                    type="submit">
                    { loading
                    ? <Spinner
                        className="loading-spinner"
                        animation="border"
                        variant="light" />
                    : "Send" }
                </button>

                { success &&
                    <p
                        className="success">
                        We've got your message!<br/> Someone from our team will get in touch with you soon.
                    </p>}

                <ReCaptcha
                    ref={ reCaptcha }
                    sitekey="6Lcj7sAZAAAAABt11lfhgT_o1SFH6Z9Nn4JNSLBz"
                    verifyCallback={ res => setcaptchaToken(res) }/>

            </form>
        </div>
    )
}

export default ContactForm;
