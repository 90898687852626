import React       from "react";

import Modal from "react-bootstrap/Modal";

// images
import sankey0 from "../../data/sankey_help_images/sankey0.png";
import sankey1 from "../../data/sankey_help_images/sankey1.png";
import sankey2 from "../../data/sankey_help_images/sankey2.png";
import sankey3 from "../../data/sankey_help_images/sankey3.png";
import sankey4 from "../../data/sankey_help_images/sankey4.png";
import sankey5 from "../../data/sankey_help_images/sankey5.png";

import "../../stylesheets/components/sankey-help-modal.css";

const SankeyHelpModal = ({ show, onHide }) => {
    return (

        <Modal
			show={ show }
			onHide={ onHide }
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			scrollable
			className="sankey-help-modal">

		<Modal.Header
			closeButton>

			<Modal.Title
				id="contained-modal-title-vcenter"
				className="modal-title">
				Sankey Diagram Usage Guide
			</Modal.Title>

		</Modal.Header>

		<Modal.Body>

            <section>

                <h4
                    className="sub-title">
                    Depth Selection
                </h4>

                <p
                    className="help-text">
                    • Control the level of abstraction of the Sankey Diagram's terms by using the Depth selector. The higher the depth, the lower the level of abstraction. In the guide below, we switch from a Depth of 3 to a Depth of 1.
                </p>

                <img
                    className="help-img"
                    src={ sankey0 }
                    alt="Depth selection start state"/>

                <img
                    className="help-img"
                    src={ sankey1 }
                    alt="Depth selection end state"/>

            </section>

            <section>

                <h4
                    className="sub-title">
                    Article Management
                </h4>

                <p
                    className="help-text">
                    • Hover over a link to draw focus onto it.
                </p>

                <img
                    className="help-img"
                    src={ sankey2 }
                    alt="Hover over link"/>

                <p
                    className="help-text">
                    • Click on a link to identify the articles that contained the terms which contributed towards forming that link. If the article is already contained in your <b>Focus</b> collection, the Article icon on the left of the title will have a <span className="blue">blue</span> fill. Else, it will have a <span className="grey">grey</span> outline.
                </p>

                <img
                    className="help-img"
                    src={ sankey3 }
                    alt="Clicking on link"/>

                <p
                    className="help-text">
                    • If an article is <b>not</b> in your <b>Focus</b> collection, you can add it by clicking on the Article icon.
                </p>

                <img
                    className="help-img"
                    src={ sankey4 }
                    alt="Adding article to focus collection"/>

                <p
                    className="help-text">
                    • Conversely, if an article is in your <b>Focus</b> collection, you can remove it by clicking on the Article icon.
                </p>

                <img
                    className="help-img"
                    src={ sankey5 }
                    alt="Remove article from focus collection"/>

            </section>

		</Modal.Body>

		</Modal>
    )
}

export default SankeyHelpModal
