import React, { useState } from "react";

import { FontAwesomeIcon }   from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

import Tooltip               from "react-bootstrap/Tooltip";
import OverlayTrigger        from "react-bootstrap/OverlayTrigger";

import OverlayTriggerIcon    from "../Utilities/OverlayTriggerIcon";

import "../../stylesheets/components/search-bar.css";

const IRSearchBar = ({ onSearchHandler, initQuery }) => {

    const [query, updateQuery] = useState(initQuery || "");

    const onSubmitHandler = (e) => {
        e.preventDefault();
        if (query.length > 0) onSearchHandler(query);
    }

    const onChangeHandler = (e) => {
        e.preventDefault();

        const value = e.target.value;
        updateQuery(value);
    }

    const clearQuery = () => {
        updateQuery("");
    }

    return (

        <form
            className="search-bar"
            onSubmit={ onSubmitHandler }>

            <div
                className="search-field-container">

                <input
                    type="text"
                    placeholder="Search literature..."
                    value={ query }
                    onChange= { onChangeHandler }
                    className="search-field"/>

                <div
                    className="clear-icon-container">
                    { query && query.length > 0 &&
                        <OverlayTriggerIcon
                            placement="bottom"
                            overlay_text="clear"
                            action={ clearQuery }
                            css_class={ "clear-icon" }
                            icon={ faTimes } /> }
                </div>

                <div
                    className="divider-icon">
                </div>

                <button
                    className="search-btn"
                    type="submit">

                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip>
                                search
                            </Tooltip>
                        }>

                        <FontAwesomeIcon
                            icon={ faSearch }
                            className="search-icon"/>

                    </OverlayTrigger>

                </button>

            </div>

        </form>
    )
}

export default IRSearchBar;