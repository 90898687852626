import React       from "react";
import { connect } from "react-redux";

import Modal from "react-bootstrap/Modal";

import RelatedArticle from "./../Utilities/RelatedArticle";

import { updateCollection } from "../../redux/actions/userActions";

import "../../stylesheets/components/literature-modal.css";

const SankeyModal = ({ show, onHide, title, relatedLit, collectionInFocus,
					addArticleToCollection, deleteArticleFromCollection }) => {

	let alias;
	let collectionID;
	let cord_uids = new Set();
	if (collectionInFocus !== undefined){
		alias = collectionInFocus.alias;
		collectionID = collectionInFocus.collectionID;
		cord_uids = new Set(collectionInFocus.articles.map(a => a.cord_uid));
	}

	const addToCollection = (cord_uid) => {
		addArticleToCollection(collectionID, cord_uid);
	}

	const delFromCollection = (cord_uid) => {
		deleteArticleFromCollection(collectionID, cord_uid);
	}

	return (

		<Modal
			show={ show }
			onHide={ onHide }
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			scrollable
			className="sankey-modal">

		<Modal.Header
			closeButton>

			<Modal.Title
				id="contained-modal-title-vcenter"
				className="modal-title">
				{ title }
			</Modal.Title>

		</Modal.Header>

		<Modal.Body>

			<h3
				className="links-title">
				Related Literature:
			</h3>

			<div
				className="links-to-relatedLit">

				{ relatedLit.map(article =>

					<RelatedArticle
						key={ article.cord_uid }
						article={ article }
						collectionName={ alias }
						inCollection={ cord_uids.has(article.cord_uid) }
						addToCol={ addToCollection }
						delFromCol={ delFromCollection }/>
				)}

			</div>

		</Modal.Body>

		</Modal>
	);
}

const mapDispatchToProps = dispatch => {
    return {
        addArticleToCollection: (collectionID, cord_uid) => dispatch(updateCollection(true, collectionID, [cord_uid])),
        deleteArticleFromCollection: (collectionID, cord_uid) => dispatch(updateCollection(false, collectionID, [cord_uid])),
    }
};

export default connect(null, mapDispatchToProps)(SankeyModal);