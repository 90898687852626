import React, { useEffect, useState, memo }  from "react";
import axios                                 from "axios";

import Spinner from "react-bootstrap/Spinner";

const LDA = ({ cord_uids }) => {

    const [loading, setLoading] = useState(true);
    const [html, setHtml] = useState("");

    useEffect(() => {

        setLoading(true);

        axios("/lda_data", {
                method: "post",
                data: {
                    cord_uids: JSON.stringify(cord_uids)
                }
            })
            .then(res => {
                setHtml(res.data);
                setLoading(false);
            })
            .catch(err => {
                setHtml("");
                setLoading(false);
            });

        }, [cord_uids]);

    let lda =

        html.length > 0

        ? <div
            className="topic-model-container">
            <iframe
                title="pyLDAvis"
                scrolling="no"
                seamless
                frameBorder="none"
                className="topic-model"
                srcDoc={ html }>
            </iframe>
        </div>
        : <h1
            className="diagram-fallback-msg">
            Sorry, something went wrong :(
            {/* Sorry, we couldn't extract enough information to render this component.<br/> Try adding more articles to your briefcase. */}
        </h1>

    return loading

        ?	<Spinner
                className="loading-spinner"
                animation="border"
                variant="dark" />

        :   ( lda )
}

const areEqual = (prevProps, nextProps) =>
    JSON.stringify(prevProps.cord_uids) === JSON.stringify(nextProps.cord_uids);

export default memo(LDA, areEqual);
