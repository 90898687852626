import React, { useState, useEffect } from "react";
import axios                          from "axios";

import Spinner from "react-bootstrap/Spinner";

import "../stylesheets/pages/verification.css";

const VerificationPage = (props) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {

        const query = new URLSearchParams(props.location.search);
        const code = query.get("code");

        if (code === undefined || code === null){
            setError("Sorry, this link appears to be invalid");
            return;
        }

        axios("/verify_registration", {
            method: "post",
            data : {
                encrypted_url: code
            },
            withCredentials: true,
            'Access-Control-Allow-Credentials': true
        })
        .then(res => {
            setLoading(false);
        })
        .catch(e => {
            if (e.response.status === 500){
                setError("Sorry, something went wrong. Please try again later")
            } else{
                setError("Sorry, this link appears to be invalid");
            }
            setLoading(false);
        })
    }, [props.location.search]);

    return (
        <div
            id="verification-page">

            <div
                className="verification-panel">

                <div
                    className="header">

                    <h2>
                        Verification Outcome:
                    </h2>
                </div>

                { loading
                ? <Spinner
                    animation="border"
                    variant="dark" />
                : (error.length > 0)
                    ? <p
                        className="error link-verification">
                        { error }
                    </p>
                    : <p
                        className="success link-verification">
                        Success
                    </p>}
            </div>
        </div>
    )
}

export default VerificationPage;


