import React, { useState, useEffect } from "react";
import { connect }                    from "react-redux";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip        from "react-bootstrap/Tooltip";

import { FontAwesomeIcon }  from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

import OverlayTriggerIcon from "../components/Utilities/OverlayTriggerIcon";
import SankeyHelpModal    from "../components/Visualisations/SankeyHelpModal";
import WordCloudViewer    from "../components/Visualisations/WordCloudViewer";
import SankeyDiagram      from "../components/Visualisations/SankeyDiagram";
import LDA                from "../components/Visualisations/LDA";

import { createNewCollection } from "../redux/actions/userActions";

import "./../stylesheets/pages/visualiser.css";

const ACTIVE_COL_INDEX = -1;

const VisualiserPage = ({ user_data, createNewCollection }) => {

	const [visColIndex, setVisColIndex] = useState(ACTIVE_COL_INDEX);
	const [focusColIndex, setFocusColIndex] = useState(0);
	const [showHelpModal, setShowHelpModal] = useState(false);

	const { collections, searchResults } = user_data;

	useEffect(() => {

		document.title = "COVID-SEE | Visualiser";

		if (searchResults.length > 0 && collections.length === 0){
			createNewCollection();
		}
	});

	let selectedCollection = [];
	if (visColIndex === ACTIVE_COL_INDEX){
		selectedCollection = searchResults;
	} else{
		selectedCollection = collections[visColIndex].articles;
	}

	const cord_uids = [...selectedCollection.map(article => article.cord_uid)];
	let collectionInFocus = collections[focusColIndex]

	return (

		<div
			id="visualiser-page"
			className="page">

			<div
				className="selection-menu panel">

				<div
					className="panel-title">
					<p>Options</p>
				</div>

				<div
					className="selections">

					<div
						className="selection">

						<label
							htmlFor="vis-collection-selector">
							<b>Visualise:</b>
							<OverlayTrigger
								placement="right"
								overlay={
									<Tooltip>
									Collection that's being used to generate the visualisations
									</Tooltip>
								}>
								<FontAwesomeIcon
									icon={ faQuestionCircle }
									className="info-icon"/>
							</OverlayTrigger>
						</label>

						<select
							id="vis-collection-selector"
							onChange={ (e) => setVisColIndex(parseInt(e.target.value)) }
							defaultValue={ ACTIVE_COL_INDEX }>

							<option
								value={ ACTIVE_COL_INDEX }
								disabled={ ACTIVE_COL_INDEX === visColIndex }>
								Search Results
							</option>

							{ user_data.collections.map((collection, index) =>
							<option
								key={ collection.collectionID }
								value={ index }
								disabled={ index === visColIndex }>
								{ collection.alias }
							</option>
							)}
						</select>
					</div>

					<div
						className="selection">

						<label
							htmlFor="mutate-collection-selector">
							<b>Focus:</b>
							<OverlayTrigger
								placement="right"
								overlay={
									<Tooltip>
									Collection to add/remove articles from based on the visualisations
									</Tooltip>
								}>
								<FontAwesomeIcon
									icon={ faQuestionCircle }
									className="info-icon"/>
							</OverlayTrigger>
						</label>

						<select
							id="mutate-collection-selector"
							onChange={ (e) => setFocusColIndex(parseInt(e.target.value)) }>

							{ user_data.collections.map((collection, index) =>
							<option
								key={ collection.collectionID }
								value={ index }>
								{ collection.alias }
							</option>
							)}
						</select>
					</div>

				</div>

			</div>

			<div>

				{ cord_uids.length > 0

				? <div
					className="grid-container">

					<div
						className="grid-item">

						<h2
							className="title sankey">
							Relational Sankey Diagram

							<OverlayTriggerIcon
								placement="right"
								overlay_text="help"
								action={ () => setShowHelpModal(true) }
								css_class="info-icon large"
								icon={ faQuestionCircle }/>
						</h2>

						<SankeyHelpModal
							show={ showHelpModal }
							onHide={ () => setShowHelpModal(false) }/>

						<div
							className="content"
							id="grid-item-sankey">

							<SankeyDiagram
								key={ "sankey-" + JSON.stringify(cord_uids) }
								cord_uids={ cord_uids }
								collectionInFocus={ collectionInFocus }/>

						</div>

					</div>

					<div
						className="grid-item">

						<h2
							className="title">
							Latent Dirichlet Allocation
						</h2>

						<div
							className="content"
							id="grid-item-topic">

							<LDA
								cord_uids={ cord_uids }/>

						</div>

					</div>

					<div
						className="grid-item">

						<h2
							className="title">
							Term Clouds
						</h2>

						<div
							className="content"
							id="grid-item-cloud">

							<WordCloudViewer
								key={ "wc-" + JSON.stringify(cord_uids) }
								cord_uids={ cord_uids }
								collectionInFocus={ collectionInFocus }/>

						</div>

					</div>

				</div>

				: <h1
					className="fallback-msg">
					Please add some articles to this collection.
				</h1> }
			</div>

		</div>

	);
}

const mapDispatchToProps = dispatch => ({
    createNewCollection: () => dispatch(createNewCollection("Collection #1", []))
});

const mapStateToProps = (state) => ({
    user_data : state
});

export default connect(mapStateToProps, mapDispatchToProps)(VisualiserPage);
