import axios from 'axios';
import {
    ADD_COLLECTION,
    UPDATE_COLLECTION,
    SET_USER_DATA,
    UPDATE_SEARCH_RESULTS,
    DEL_COLLECTION,
    CHANGE_COLLECTION_NAME,
    SET_AUTHENTICATED,
    SET_UNAUTHENTICATED
} from '../types';

/**
 * Fetches the user's briefcase
 */
export const fetchUserData = () => (dispatch) => {

    axios({
            method : 'get',
            url    : '/user_data',
            withCredentials: true,
            'Access-Control-Allow-Credentials': true
        })
        .then(res => {
            dispatch({
                        type    : SET_USER_DATA,
                        payload : res.data
                    });
        })
        .catch(err => console.log(err));
}

/**
 * Links/ Unlinks articles from the user's briefcase
 */
export const updateCollection = (add, collectionID, cord_uids) => (dispatch) => {

    let url;
    if (add){
        url = "/add_to_collection";
    } else {
        url = "/remove_from_collection";
    }

    axios(url, {
            method: "post",
            data: {
                collectionID: collectionID,
                cord_uids: JSON.stringify(cord_uids)
            }
        })
        .then(res => {
            dispatch({
                type: UPDATE_COLLECTION,
                payload: res.data
            });
        })
        .catch(err => console.log(err));
}

export const createNewCollection = (alias, cord_uids) => (dispatch) => {

    axios("/new_collection", {
            method: "post",
            data: {
                alias: alias,
                cord_uids: JSON.stringify(cord_uids)
            },
            withCredentials: true,
            'Access-Control-Allow-Credentials': true
        })
        .then(res => {
            dispatch({
                type: ADD_COLLECTION,
                payload: res.data
            });
        })
        .catch(err => console.log(err));
}

export const deleteCollection = (collectionID) => (dispatch) => {

    axios("/del_collection", {
            method: "delete",
            data: {
                collectionID: collectionID
            }
        })
        .then(_ => {
            dispatch({
                type: DEL_COLLECTION,
                payload: collectionID
            })
        })
        .catch(err => console.log(err));
}

export const changeCollectionName = (collectionID, newName) => (dispatch) => {

    axios("/change_name", {
            method: "put",
            data: {
                collectionID: collectionID,
                newName: newName
            }
        })
        .then(_ => {
            dispatch({
                payload: { "collectionID" : collectionID, "newName" : newName },
                type: CHANGE_COLLECTION_NAME,
            })
        })
        .catch(err => console.log(err));
}

export const updateSearchResults = (add, cord_uids) => (dispatch) => {

    let url;
    if (add){
        url = "/add_to_search_results";
    } else {
        url = "/remove_from_search_results";
    }

    axios(url, {
            method: "post",
            data: {
                cord_uids: JSON.stringify(cord_uids)
            },
            withCredentials: true,
            'Access-Control-Allow-Credentials': true
        })
        .then(res => {
            dispatch({
                type: UPDATE_SEARCH_RESULTS,
                payload: res.data
            });
        })
        .catch(err => console.log(err)
    );
}

export const overwriteSearchResults = (cord_uids) => (dispatch) => {

    dispatch({
        type: UPDATE_SEARCH_RESULTS,
        payload: cord_uids
    });

}

export const setAuthenticated = (name) => (dispatch) => {

    dispatch({
        type: SET_AUTHENTICATED,
        payload: name
    });
}

export const setUnAuthenticated = () => (dispatch) => {

    dispatch({
        type: SET_UNAUTHENTICATED,
        payload: null
    });
}