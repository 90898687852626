import React, { useState, useEffect, useRef } from "react";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { faTimes, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

import OverlayTriggerIcon from "../Utilities/OverlayTriggerIcon";
import SSHelpModal        from "./SSHelpModal";

import QueryComponent from "./QueryComponent";

import "../../stylesheets/components/search-bar.css";
import "../../stylesheets/components/structured-search-builder.css";

const StructuredSearchBuilder = ({ onSearchHandler, meshTerms}) => {

    const optionsPanel = useRef();

    const [query, updateQuery] = useState([]);
    const [searchStr, updateSearchStr] = useState("");
    const [showSuggestions, setShowSuggestions] = useState(true);
    const [suggestions, updateSuggestions] = useState([]);
    const [isQueryInValid, setIsQueryInValid] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        }
    }, []);

    const onSubmitHandler = () => {

        let searchQuery = [];

        for (let i=0; i<query.length; i++){
            let qc = query[i];
            if (qc.type === "sym"){
                searchQuery.push(qc.val);
            } else{
                searchQuery.push(qc.qc);
            }
        }

        onSearchHandler(searchQuery);
    }

    const onDeleteQC = (index) => {
        let query_ = [...query];
        query_.splice(index, 1);
        updateQuery(query_);

        validateQuery(query_);
    }

    const onChangeHandler = (e) => {
        e.preventDefault();

        const value = e.target.value;
        updateSearchStr(value);

        if (value.length > 0){
            const re = new RegExp(`^${ value }`, 'i');
            let suggestions_ = meshTerms
                                .filter(x => re.test(x.term))
                                .sort((x, y) => y.freq - x.freq)
            updateSuggestions(suggestions_);
        }
    }

    const clearSearchStr = () => {
        updateSearchStr("");
    }

    const handleClick = (e) => {
        if (optionsPanel.current.contains(e.target)){
            setShowSuggestions(true);
        } else{
            setShowSuggestions(false);
        }
    }

    const addQeuryComponent = (type, val) => {

        let val_ = val;
        let class_ = null;
        let query_ = [...query];

        if (type === "term"){

            val_ = val.term;
            class_ = `term ${ val.PIO_type}`;
        }

        query_.push({ type: type, val: val_, class: class_, qc: val });
        updateQuery(query_);

        validateQuery(query_);
    }

    const validateQuery = (query) => {

        let no_opening_brackets = 0;
        let prev_qc = null;
        let invalid = false;

        for (let i=0; i<query.length; i++){

            let curr_qc = query[i];

            // ensure that all the brackets are closed off
            if (curr_qc.val === '(') no_opening_brackets += 1;
            if (curr_qc.val === ')'){

                if (no_opening_brackets === 0){
                    invalid = true;
                    break;
                }

                no_opening_brackets -= 1;
            }

            // ensure that the query elements of the same type aren't repeating
            if (prev_qc !== null && curr_qc.type === prev_qc.type){
                if (curr_qc.type === "sym"){
                    if (!((prev_qc.val === ')' && curr_qc.val === ')') ||
                            (prev_qc.val === '(' && curr_qc.val === '('))) {
                        invalid = true;
                        break;
                    }
                } else {
                    invalid = true;
                    break;
                }
            }

            prev_qc = curr_qc;
        }

        if ((query.length === 1 && query[0].type !== "term") ||
            (query.length > 1 &&
                (no_opening_brackets > 0 ||
                query[query.length-1].type === "op" ||
                query[0].type === "op"))) invalid = true;

        setIsQueryInValid(invalid);
    }

    const onDragEnd = (moveEvent) => {

        if (!moveEvent.destination) return;

        const { source, destination } = moveEvent;

        let query_ = [...query];
        let qc = query_[source.index];
        query_.splice(source.index, 1);
        query_.splice(destination.index, 0, qc);

        updateQuery(query_);
        validateQuery(query_);
    }

    return (

        <div
            className="structured-search-builder">

            <div
                className="query-builder-container">

                <OverlayTriggerIcon
                    placement="right"
                    overlay_text="help"
                    action={ () => setShowHelpModal(true) }
                    css_class="info-icon medium"
                    icon={ faQuestionCircle }/>

                <SSHelpModal
                    show={ showHelpModal }
                    onHide={ () => setShowHelpModal(false) }/>

                <span
                    className="_title">
                    Query:
                </span>

                <DragDropContext
                    onDragEnd={ moveEvent => onDragEnd(moveEvent) }>

                    <Droppable
                        direction="horizontal"
                        droppableId="main-col">

                        {(provided, snapshot) => (

                        <div
                            {...provided.droppableProps}
                            ref={ provided.innerRef }
                            className="query-builder">

                            { query.map((component, index) => (

                            <Draggable
                                key={ `${ component.val }-${ index }` }
                                draggableId={ `${ component.val }-${ index }` }
                                index={ index }>

                                {(provided, snapshot) => (

                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}>

                                    <QueryComponent
                                        key={ "query-component-" + index }
                                        val={ component.val }
                                        classNames={ component.type === "term" ? component.class : component.type }
                                        onDelete={ () => onDeleteQC(index) }/>
                                </div>)}

                            </Draggable>))}

                        </div>)}

                    </Droppable>
                </DragDropContext>

                { isQueryInValid &&
                <span
                    className="validity-msg">
                    Your query may be malformed, please re-check it.
                </span>}

                { query.length > 0 &&
                <button
                    className="custom-btn btn-blue search-btn"
                    onClick={ () => onSubmitHandler() }
                    disabled={ isQueryInValid }>
                    search
                </button>}
            </div>

            <div
                className="options-panel"
                ref={ optionsPanel }>

                <div
                    className="eqn-symbols">

                    <span
                        className="_title">
                        Logical Operators:
                    </span>

                    <div
                        className="buttons">

                        <button
                            onClick={ () => addQeuryComponent("sym", '(') }>
                            (
                        </button>
                        <button
                            onClick={ () => addQeuryComponent("sym", ')') }>
                            )
                        </button>
                        <button
                            onClick={ () => addQeuryComponent("op", "AND") }>
                            AND
                        </button>
                        <button
                            onClick={ () => addQeuryComponent("op", "OR") }>
                            OR
                        </button>
                        {/* <button
                            onClick={ () => addQeuryComponent("sym", "NOT") }>
                            NOT
                        </button> */}
                    </div>

                </div>

                <div
                    className="divider">
                </div>

                <div
                    className="search">

                    <span
                        className="_title">
                        Find MESH Terms:
                    </span>

                    <div
                        className="search-bar structured">

                        <div
                            className="search-field-container">

                            <input
                                type="text"
                                placeholder="Find terms..."
                                value={ searchStr }
                                onChange= { onChangeHandler }
                                className="search-field structured"/>

                            <div
                                className="clear-icon-container">
                                { searchStr && searchStr.length > 0 &&
                                <OverlayTriggerIcon
                                    placement="bottom"
                                    overlay_text="clear"
                                    action={ clearSearchStr }
                                    css_class={ "clear-icon" }
                                    icon={ faTimes } /> }
                            </div>

                        </div>

                        { searchStr.length > 0 && showSuggestions &&
                        <div
                            className="suggestions">

                            { suggestions.length > 0

                            ? suggestions.map((suggestion, index) => (
                                <button
                                    className="suggestion"
                                    key={ `${ suggestion.term }-${ suggestion.PIO_type }-${ index }` }
                                    onClick={ () => addQeuryComponent("term", suggestion) }
                                    type="button">
                                    <div
                                        className={ "term-type " + suggestion.PIO_type }>
                                        { suggestion.PIO_type }
                                    </div>
                                    <div
                                        className="term-name">
                                        { suggestion.term }
                                    </div>
                                </button>
                            ))

                            : <p
                                className="suggestion-failure">
                                No suggestions available.
                            </p>

                            }
                        </div>}

                    </div>
                </div>
            </div>

        </div>
    )
}

export default StructuredSearchBuilder;