import React, { Component } from "react";
import { connect }          from "react-redux";
import axios                from "axios";

import StructuredSearchBuilder from "../components/Search/StructuredSearchBuilder";
import SearchResults           from "../components/Search/SearchResults";

import "../stylesheets/pages/search.css";

import { overwriteSearchResults } from "../redux/actions/userActions";

class StructuredSearchPage extends Component{

	constructor(props){
		super(props);

		this.state = {
			query: [],
			loading: true,
			results_data: [[], []],
			error: false,
			meshTerms: [],
			selectedTerms: new Set(),
		}
	}

	componentDidMount(){

		document.title = "COVID-SEE | Structured Search";

        axios("/get_mesh_terms", {
                method: "get",
                withCredentials: true,
                'Access-Control-Allow-Credentials': true
            })
            .then(res => {
                this.setState({
					meshTerms: res.data
				});
            })
            .catch(e => {
                this.setState({
					error: true
				});
            });
	}

	onSearchHandler = (query) => {

		this.setState({
			query : query,
			error: false,
			loading: true
		});

        axios("/search", {
            method: "post",
            data : {
                query: JSON.stringify(query),
                isIR: false
            },
            withCredentials: true,
            'Access-Control-Allow-Credentials': true
        })
        .then(res => {
            this.setState({
				results_data: res.data,
				loading: false,
				error: false
			});
            this.props.overwriteSearchResults(res.data[0]);
        })
        .catch(e => {
            this.setState({
				error: true,
				loading: false
			});
		});
	}

	onAddDeleteTerm = (term) => {
		let selectedTerms = new Set(this.state.selectedTerms);
		if (!selectedTerms.has(term)){
			selectedTerms.add(term);
			this.setState({ selectedTerms: selectedTerms });
		}
	}

	render(){

		const { loading, results_data, query, error, meshTerms } = this.state;

		return (

			<div>

				<div
					id="structured-search-page"
					className="page">

					<StructuredSearchBuilder
						onSearchHandler={ this.onSearchHandler }
						meshTerms={ meshTerms }/>

					{ query.length > 0 &&
					<SearchResults
						loading={ loading }
						results_data={ results_data }
						error={ error }/>}

				</div>

			</div>
		)
	}
}

const mapDispatchToProps = dispatch => {
	return {
		overwriteSearchResults: (results) => dispatch(overwriteSearchResults(results))
	};
};

export default connect(null, mapDispatchToProps)(StructuredSearchPage);
