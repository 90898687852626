import React, { useEffect } from "react";
import { NavLink }          from 'react-router-dom';

import "../stylesheets/pages/about.css";
import logo from "../data/covidsee_logo.png";

import AOS from 'aos';

import 'aos/dist/aos.css';

const AboutPage = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div
            id="about-page"
            className="page">

            <img
                src={ logo }
                alt="COVID-SEE logo"
                className="about-logo"/>

            <section
                className="about-section"
                data-aos="fade-up"
                data-aos-delay="50"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-once="true">

                <h4
                    className="sub-title">
                    About
                </h4>

                <p
                    className="paragraph">
                    COVID-SEE is the COVID Scientific Evidence Explorer, developed by a team from the University of Melbourne led by Karin Verspoor. The core team includes Simon Suster, Yulia Otmakhova, Zenan Zhai, Biaoyan Fang, and we received help from colleagues Jey-Han Lau and Tim Baldwin. The concept was made real with the help of software developer Shevon Mendis.
                    <br/><br/>
                    The team had additional support from external collaborators Antonio Jimeno Yepes and David Martinez at IBM Research Melbourne, and Bevan Koopman at the CSIRO Australian e-Health Research Centre.
                </p>

                <p
                    className="paragraph">
                    The system uses the <a href="https://arxiv.org/abs/2004.10706" target="_blank" rel="noopener noreferrer">Covid-19 Open Research Dataset (CORD-19)</a>, which is a collection of scientific papers on Covid-19 and related historical coronavirus research and utilises the <a href="https://covidex.ai/" target="_blank" rel="noopener noreferrer">Covidex</a> search engine for the information retrieval components. Semantic analysis of biomedical concepts was performed using the <a href="https://metamap.nlm.nih.gov/" target="_blank" rel="noopener noreferrer">MetaMap</a> toolfrom the US National Library of Medicine; PICO extraction was supported by the <a href="https://github.com/bepnye/EBM-NLP" target="_blank" rel="noopener noreferrer">EBM-NLP</a> project.
                    &nbsp;<a href="https://neo4j.com/" target="_blank" rel="noopener noreferrer">Neo4J</a> was adopted as the database back-end.
                    The website was built with <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">React</a> and the <a href="https://flask.palletsprojects.com/en/1.1.x/" target="_blank" rel="noopener noreferrer">Flask</a> web development framework, and visualisations are supported by <a href="https://github.com/plouc/nivo" target="_blank" rel="noopener noreferrer">Nivo</a>, <a href="https://github.com/chrisrzhou/react-wordcloud" target="_blank" rel="noopener noreferrer">React Wordcloud</a> and <a href="https://github.com/bmabey/pyLDAvis" target="_blank" rel="noopener noreferrer">PyLDAvis</a>. Core analysis was done in Python.
                </p>

            </section>

            <section
                className="usage-section"
                data-aos="fade-up"
                data-aos-delay="50"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-once="true">

                <h4
                    className="sub-title">
                    Features
                </h4>

                <ul>
                    <li>
                        Search for, review and collect articles using <NavLink to="/search">keywords</NavLink> or the <NavLink to="/structured_search">PICO-structured Semantic Search</NavLink> facility
                    </li>
                    <li>
                        Organise, export and maintain your collections within the <NavLink to="/collections">Collections</NavLink> page
                    </li>
                    <li>
                        Examine visual overviews of your collections on the <NavLink to="/visualiser">Visualiser</NavLink> page
                    </li>
                </ul>

            </section>

            <section
                className="feedback-section"
                data-aos="fade-up"
                data-aos-delay="50"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-once="true">

                <h4
                    className="sub-title">
                    Feedback
                </h4>

                <p
                    className="paragraph">
                    If you use the COVID-SEE system for your research, please cite the system description paper <a href="https://arxiv.org/abs/2008.07880" target="_blank" rel="noopener noreferrer">here</a> — and let us know, as we’d love to hear your feedback! (Email: <a href="mailto:karin.verspoor@unimelb.edu.au">karin.verspoor@unimelb.edu.au</a>)
                </p>
            </section>
        </div>
    )
}

export default AboutPage;
