import React, { useEffect, useState } from "react";
import axios                          from "axios";

import { ResponsiveSankey } from "@nivo/sankey";

import Spinner from "react-bootstrap/Spinner";

import LiteratureModal from "./LiteratureModal";

import "../../stylesheets/components/sankey.css";

const DEFAULT_DEPTH = 1;

const SankeyDiagram = ({ cord_uids, collectionInFocus }) => {

    const [loading, setLoading] = useState(true);
    const [sankeyData, setSankeyData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedLink, setSelectedLink] = useState(null);
    const [relatedLiterature, setRelatedLiterature] = useState(null);
    const [depth, setDepth] = useState(DEFAULT_DEPTH);

    const onLinkSelect = (obj) => {

        const isLink = obj.hasOwnProperty("relatedLit");

        if (isLink){
            const { source, target, relatedLit } = obj;
            setSelectedLink(`${ source.label } → ${ target.label }`)
            setRelatedLiterature(relatedLit);
            setShowModal(true);
        } else {

            // const { type, label } = obj;

            // setLoading(true);

            // axios("/refined_sankey_data", {
            //         method: "post",
            //         data: {
            //             cord_uids: JSON.stringify(cord_uids),
            //             pio_type: type,
            //             pio_text: label
            //         }
            //     })
            //     .then(res => {
            //         setSankeyData(res.data);
            //         setLoading(false);
            //     })
            //     .catch(err => {
            //         setSankeyData(null);
            //         setLoading(false);
            //     });
        }
    }

    useEffect(() => {

        setLoading(true);

        axios("/sankey_data", {
                method: "post",
                data: {
                    cord_uids: JSON.stringify(cord_uids)
                }
            })
            .then(res => {
                setSankeyData(res.data);
                setLoading(false);
            })
            .catch(err => {
                setSankeyData(null);
                setLoading(false);
            });
    }, []);

    let data = sankeyData ? sankeyData[depth] : null;
    let depthsAvailable = sankeyData ? Object.keys(sankeyData).sort() : [];

    let sankeyDiagram =

        (data && data.nodes.length > 0 && data.links.length > 0)

        ? <div
            className="sankey-diagram-container">

            <div
                className="depth-selector-container">

                <label
                    htmlFor="depth-selector">
                    Depth:
                </label>

                <select
                    id="depth-selector"
                    onChange={ (e) => setDepth(e.target.value) }>
                    { depthsAvailable.map(depth_ =>
                        <option
                            key={ `depth#${ depth_ }` }
                            value={ depth_ }
                            disabled={ depth_ === depth }
                            selected={ depth_ === depth }>
                            { depth_ }
                        </option>)}
                </select>

            </div>

            <div
                className="symbols">

                { data.types.includes("P") &&
                <span>Population</span>}

                { data.types.includes("I") &&
                <span>Intervention</span>}

                { data.types.includes("O") &&
                <span>Outcome</span>}

            </div>


            <div
                className="sankey-diagram">

                <ResponsiveSankey
                    data={ data }
                    align="center"
                    // colors={["#1d82e0"]}
                    colors={["#094183"]}
                    nodeOpacity={1}
                    nodeThickness={10}
                    nodeSpacing={24}
                    nodeBorderWidth={0}
                    linkOpacity={0.3}
                    linkHoverOthersOpacity={0.1}
                    label={ d => d.label }
                    labelPadding={16}
                    labelTextColor={"black"}
                    animate={true}
                    motionStiffness={140}
                    motionDamping={13}
                    onClick={ obj => onLinkSelect(obj)}/>
            </div>

            { selectedLink &&
            <LiteratureModal
                show={ showModal }
                onHide={ () => setShowModal(false) }
                title= { selectedLink }
                relatedLit={ relatedLiterature }
                collectionInFocus={ collectionInFocus }/>}

        </div>

        : <h1
            className="diagram-fallback-msg">
            Sorry, something went wrong :(
        </h1>

    return loading

            ?	<Spinner
                    className="loading-spinner"
                    animation="border"
                    variant="dark" />

            :   ( sankeyDiagram )
}

export default SankeyDiagram;