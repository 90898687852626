import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp }       from "@fortawesome/free-solid-svg-icons";

import SearchResultsSkeleton from "../Skeletons/SearchResultsSkeleton";
// import SearchFilterSkeleton  from "../Skeletons/SearchFilterSkeleton";
import SelectionPane         from "./SelectionPane";
import SelectedArticle       from "./SelectedArticle";
import SearchFilter          from "./SearchFilter";
import SearchResult          from "./SearchResult";
import Pagination            from "./Pagination";

import "../../stylesheets/components/search-results.css"

const RESULTS_PER_PAGE = 10;
const NUM_SKELETON_RESULTS = 3;

const SCROLL_UP_HEIGHT = 200;

class SearchResults extends Component{

    constructor(props){
        super(props);

        const { results_data } = props;
        const [ articles, filter_info ] = results_data;

        this.state = {
            currPage: 1,
            transitioning: false,
            filters: {},
            allArticles: articles,
            filterInfo: filter_info,
            selectedArticles: new Set(),
            selectedArticle: null,
            hasScrolled: false
        }
    }

    componentDidMount = () => {
        window.addEventListener("scroll", this.onScroll);
    }

    onScroll = () => {
        let scrollPos = document.body.scrollTop || document.documentElement.scrollTop;

        if (scrollPos >= SCROLL_UP_HEIGHT && !this.state.hasScrolled) {
            this.setState({ hasScrolled: true });
        } else if (scrollPos < SCROLL_UP_HEIGHT && this.state.hasScrolled) {
            this.setState({ hasScrolled: false });
        }
    }

    scrollUp = () => {
        this.setState({ hasScrolled: false });
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    setPage = (pageNum) => {

        if (pageNum === this.state.currPage) return;

        window.scrollTo({
            top: 0,
            // behavior: "smooth"
        });

        this.setState({
            currPage: pageNum,
            transitioning: true
        });

        setTimeout(() => {
            this.setState({ transitioning: false })
        }, 1000);
    }

    updateFilters = (field, values) => {

        this.setState({
            currPage: 1,
            transitioning: true
        });

        const { filters } = this.state;
        if (!(field in filters))
            filters[field] = [];

        for (let i=0; i<values.length; i++){
            let value = values[i];
            let index = filters[field].indexOf(value);
            if (index >= 0){
                filters[field].splice(index, 1);
            } else{
                filters[field].push(value);
            }
        }

        if (filters[field].length === 0) delete filters[field];

        setTimeout(() => {
            this.setState({ filters : filters, transitioning: false });
        }, 1000);
    }

    resetFilters = () => {

        if (Object.keys(this.state.filters).length === 0) return;

        this.setState({
            currPage: 1,
            transitioning: true
        });

        document.querySelectorAll('.search-filter input[type=checkbox]').forEach( el => el.checked = false );

        setTimeout(() => {
            this.setState({ filters : {}, transitioning: false });
        }, 1000);
    }

    onSelectArticle = (article) => {

        const { selectedArticles } = this.state;

        const newSelectedArticles = new Set(selectedArticles);

        if (newSelectedArticles.has(article)){
            newSelectedArticles.delete(article);
        } else{
            newSelectedArticles.add(article);
        }

        this.setState({ selectedArticles : newSelectedArticles });
    }

    onToggleArticle = (article) => {

        const selectedArticle = this.state.selectedArticle;

        if (selectedArticle && article.cord_uid === selectedArticle.cord_uid){
            this.setState({ selectedArticle : null });
        } else {
            this.setState({ selectedArticle : article });
        }
    }

    onCloseArticle = (article) => {
        this.setState({ selectedArticle : null });
    }

    render(){

        const { loading, results_data, error } = this.props;
        const [ allArticles, filterInfo ] = results_data;
        const { currPage, transitioning, filters, selectedArticle, selectedArticles, hasScrolled } = this.state;

        const selectedCordUIDs = Array.from(selectedArticles).map(article => article.cord_uid);

        let filteredArticles = allArticles.slice()

        // filter the articles
        for (const [field, values] of Object.entries(filters)) {
            for (let i=0; i<values.length; i++){
                filteredArticles = filteredArticles.filter(article => {

                    if (Array.isArray(article[field])){
                        for (let j=0; j<article[field].length; j++){
                            if (values.includes(article[field][j])) return true;
                        }
                    } else{
                        return values.includes(article[field]);
                    }

                    return false;
                });
            }
        }

        const numArticles = filteredArticles.length;

        let indexOfLastResult = currPage * RESULTS_PER_PAGE;
        let indexOfFirstResult = indexOfLastResult - RESULTS_PER_PAGE;
        let currArticles = filteredArticles.slice(indexOfFirstResult, indexOfLastResult);

        return (
            <div
                className="search-results-container">

                { loading

                ?   (error

                    ?   <p
                            className="search-metrics">
                            Sorry, something went wrong :(
                        </p>
                    :   <p
                            className="search-metrics">
                            &nbsp;
                        </p>)
                :   <p
                        className="search-metrics">
                        { numArticles > 0 ? `We found ${ numArticles } potential match${ numArticles === 1 ? ':' : "es:" }` : "Sorry, we couldn't find any matches" }
                    </p>
                }

                <div
                    className="search-details-container">

                    <div
                        className="search-details">

                        <SearchFilter
                            filter_info={ filterInfo }
                            loading={ loading }
                            onAddFilter={ this.updateFilters }
                            onResetFilters={ this.resetFilters}/>

                        { loading || transitioning

                            ? <SearchResultsSkeleton
                                nResults= { NUM_SKELETON_RESULTS }/>

                            : <div
                                className="search-results">

                                { currArticles.map((article, index) => (

                                    <SearchResult
                                        key = { article.cord_uid }
                                        article = { article }
                                        onSelect = { this.onSelectArticle }
                                        inSelection = { selectedCordUIDs.includes(article.cord_uid) }
                                        expanded = { selectedArticle && selectedArticle.cord_uid === article.cord_uid }
                                        onToggleArticle = { this.onToggleArticle }/>
                                ))}

                                { hasScrolled &&
                                    <button
                                        className="scroll-up-btn"
                                        onClick={ this.scrollUp }>
                                        Scroll Up&nbsp;&nbsp;<FontAwesomeIcon
                                            icon={ faArrowUp }
                                            className="arrow-up-icon"/>
                                    </button>}

                                { filteredArticles.length > 0 && allArticles.length >= RESULTS_PER_PAGE &&

                                <Pagination
                                    resultsPerPage={ RESULTS_PER_PAGE }
                                    totalResults={ filteredArticles.length }
                                    paginate={ this.setPage }
                                    currPage={ currPage }/>}

                            </div>
                        }

                    </div>

                    <div
                        className="selection-details">

                        <SelectionPane
                            articles={ selectedArticles }
                            cord_uids={ selectedCordUIDs }/>

                        { selectedArticle &&
                        <SelectedArticle
                            article={ selectedArticle }
                            onClose={ this.onCloseArticle }/>
                        }
                    </div>

                </div>

            </div>
        )
    }
}

export default SearchResults;