import React from "react";

import "../stylesheets/pages/error.css";

const ErrorPage = () => (
	<div
		id="error-page">
		<h1
			className="error-msg">
			Whoops, this page does not exist!
		</h1>
	</div>
)

export default ErrorPage;
