import React from "react";

import Tooltip        from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import "../../stylesheets/components/search-result.css";

const SearchResult = ({ article, onSelect, onToggleArticle, expanded, inSelection }) => {

    const { journal, title, authors, abstract, url, publish_time } = article

    return (
        <div
            className={ "search-result" + (inSelection ? " selected" : "" )}>

            <input
                type="checkbox"
                checked={ inSelection }
                onChange={ () => onSelect(article) }
                className="selector">
            </input>

            <div
                className="result-title">
                <a
                    href={ url[0] }
                    target="_blank"
                    className="result-title"
                    rel="noopener noreferrer">
                    { title }
                </a>
            </div>

            <div
                className="meta-info">
            <div
                className="meta-field">
                <div
                    className="field-title">
                    Authors:
                </div>
                <div
                    className="field-info">
                    { authors }
                </div>
            </div>

            <div
                className="meta-field">
                <div
                    className="field-title">
                    Journal:
                </div>
                <div
                    className="field-info">
                    { `${ journal }, ${ publish_time }` }
                </div>
            </div>
            </div>

            <p
                className= "result-abstract">
                { abstract === "N/A" ? "" : abstract.replace(/^(.{300}[^\s]*).*/, "$1") + "..." }

                <OverlayTrigger
                    placement="right"
                    overlay={
                    <Tooltip>
                        { expanded ? "close" : "expand" }
                    </Tooltip>
                    }>
                    <button
                        className="toggle-abstract-btn"
                        onClick={ () => onToggleArticle(article) }>
                        { expanded ? "[-]" : "[+]" }
                    </button>
                </OverlayTrigger>
            </p>

        </div>
    )
}

export default SearchResult;

